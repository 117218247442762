import "./link.scss";

import * as React from "react";
import css from "classnames";
import { push as pushTo } from "connected-react-router";
import Touchable from "../touchable";
import { connect } from "react-redux";
import { isPageAnimating } from "../../helpers/utils";

let tid: any = null;

export function Link({
	to,
	children,
	delay = 0,
	disabled = false,
	className,
	push,
	onClick = () => {
		// ..
	},
}: any) {
	return (
		<Touchable disabled={disabled}>
			<a
				onClick={(e) => {
					e.preventDefault();

					// if route animation is happening, don't navigate again, simply ignore
					// Touchable will also not do touching styles if route nav anim is not finished
					if (!isPageAnimating() && !disabled) {
						clearTimeout(tid);

						// allow timeouts, useful for checkout that wants the user to be able to see
						// their choice be activated before navigation away
						tid = setTimeout(() => {
							push(to.pathname ? to : { pathname: to });
							onClick(e);
						}, delay);
					}
				}}
				href={to.pathname ? to.pathname : to}
				className={css(className, "link", { "link--disabled": disabled })}
			>
				{children}
			</a>
		</Touchable>
	);
}

export default connect(
	null,
	(dispatch) => {
		return {
			push: (to: any) => dispatch(pushTo(to)),
		};
	},
)(Link);
