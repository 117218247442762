import * as React from "react";
import classNames from "classnames";
import "./_row.scss";

export interface IRowProps {
	children: any;
	className?: string;
}

const Row: React.FunctionComponent<IRowProps> = (props) => {
	const rowClass = classNames("c-row", props.className);
	return <section className={rowClass}>{props.children}</section>;
};

export default Row;
