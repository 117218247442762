import { Orders } from "@sno_oslo/browser-utils";

// all settings should be put here, so settings arent sprinkled randomly around the app
// including localStorage keys
export default {
	// env dictates rest endpoint, and stuff, and will default to development | production
	ENV: new URLSearchParams(window.location.search.substring(1)).get("env") || "development",
	NODE_ENV: process.env.NODE_ENV,
	IGNORE_DEBUG: new URLSearchParams(window.location.search.substring(1)).has("ignoredebug"),
	APP_VERSION: process.env.REACT_APP_VERSION,
	STRIPE_API_KEY: process.env.REACT_APP_STRIPE_API_KEY,
	REST_API_KEY: process.env.REACT_APP_REST_API_KEY,
	REST_API_URL: process.env.REACT_APP_REST_API_URL,
	STORAGE_TOKEN: "token",
	STORAGE_USER: "user",
	STORAGE_PREVIOUS_APP_VERSION: "previous-app-version",
	ORDERMANAGER_OPTIONS: {
		token: "",
		apiKey: "71RfPu3gtI6uTnTdMZHwj479kQsGMfz69SNrGWTW",
		env: Orders.SERVICE_ENV.DEVELOPMENT,
	},
};
