import * as authActions from "./creators/auth";
import { getUser } from "../../store/actions/user";
import LocalStorage from "../../store/LocalStorage";
import Config from "../../store/Config";

export function login(token: string): any {
	return async (dispatcher: (type: any) => any) => {
		dispatcher(authActions.login(token));

		LocalStorage.set(Config.STORAGE_TOKEN, token);

		await Promise.all([dispatcher(getUser())]);
	};
}

export function logout(): any {
	return (dispatcher: (type: any) => any) => {
		dispatcher(authActions.logout());
		localStorage.clear();
	};
}
