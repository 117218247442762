import "./_page-summary.scss";

import * as React from "react";
import { Page, Container, ScrollView, PageHeader, ForwardLink, ButtonGroup } from "../../../components";
import OrderSummary from "../../../components/ordersummary";
import { Orders } from "@sno_oslo/browser-utils";
const { OrderManager } = Orders;

const Summary = (props: any) => {
	const history = props.history;
	const typeOfSubscription = props.match.params.type;
	const numberofdays = props.match.params.type;
	const backPath =
		props.match.params.type === "adult" || props.match.params.type === "youth"
			? `/products/buy/terms/${typeOfSubscription}`
			: `/products/buy/terms/${numberofdays}`;

	const backHandler = () => {
		history.push({ pathname: backPath, state: { direction: "left" } });
	};

	const closeHandler = () => {
		OrderManager.removeAllProducts();
		history.push({ pathname: "/products/buy", state: { direction: null } });
	};

	return (
		<Page className="page-summary" popup>
			<PageHeader closeHandler={closeHandler} backHandler={backHandler} backText="Vilkår" />
			<ScrollView>
				<Container>
					<h2>Oppsummering</h2>
					<OrderSummary expanded history={history} />
				</Container>
				<ButtonGroup align="bottom">
					<ForwardLink
						arrow
						button
						wide
						to={
							props.match.params.type === "adult" || props.match.params.type === "youth"
								? `/products/buy/payment/${typeOfSubscription}`
								: `/products/buy/payment/${numberofdays}`
						}
					>
						Til betaling
					</ForwardLink>
				</ButtonGroup>
			</ScrollView>
		</Page>
	);
};
export default Summary;
