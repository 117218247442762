import * as React from "react";
import "./_arrow.scss";

export interface IArrowProps {
	className: string;
	arrowText?: string;
	arrowLink?: string;
}

const Arrow: React.FunctionComponent<IArrowProps> = (props) => {
	return (
		<a className="c-arrow--link" href={props.arrowLink}>
			<span className="c-arrow--text">{props.arrowText}</span>
			<svg className="c-arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 68 10">
				<g fill="none" fill-rule="evenodd" stroke-width="2">
					<polyline points="58 2 64 2 64 8" transform="rotate(45 61 5)" />
					<path stroke-linecap="square" d="M65,5 L0.63999939,5" />
				</g>
			</svg>
		</a>
	);
};

export default Arrow;
