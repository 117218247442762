import * as React from "react";
import { connect } from "react-redux";
import { getOnline } from "../../store/selectors/app";
import Button from "../button";

// display an error screen if passed error prop failed
// with retry button
export function ErrorGuard(props: any) {
	if (!props.error || (props.ignoreWhenOffline && !props.online)) {
		return <>{props.children}</>;
	} else {
		return (
			<div>
				<h1 className="h3 h3--first">{props.title}</h1>
				<p className="p">Prøv igjen eller whatever</p>

				<Button
					onClick={() => {
						if (!props.loading) {
							props.retryAction();
						}
					}}
				>
					Prøv på nytt {props.loading ? " (LASTER...) " : null}
				</Button>
			</div>
		);
	}
}

export default connect((state) => {
	return {
		online: getOnline(state),
	};
})(ErrorGuard);
