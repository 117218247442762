import "./styles/main.scss";

import * as React from "react";
import * as ReactDOM from "react-dom";
import { Provider as ReduxProvider } from "react-redux";

import { AppWrapper, ErrorBoundary } from "./components";
import AuthGate from "./pages/auth/AuthGate";
import { routes, INITIAL_ROUTE } from "./routes/routes";
import { Fetch } from "./store/Fetch";
import { makeStore, authMiddleware, startupBootstrap, nativeBootstrap, Config } from "./store/";
import * as serviceWorker from "./serviceWorker";
import { createMemoryHistory } from "history";
import { StripeProvider, Elements } from "react-stripe-elements";
import { ConnectedRouter } from "connected-react-router";

const history = createMemoryHistory({
	initialEntries: [INITIAL_ROUTE],
	initialIndex: 0,
});
const store = makeStore(history);

Fetch.addRequestMiddleware(authMiddleware(store));

startupBootstrap(store);
nativeBootstrap(store);

ReactDOM.render(
	<ErrorBoundary>
		<StripeProvider apiKey={Config.STRIPE_API_KEY}>
			<Elements locale="no">
				<ReduxProvider store={store}>
					<AuthGate>
						<ConnectedRouter history={history}>
							<AppWrapper routes={routes} />
						</ConnectedRouter>
					</AuthGate>
				</ReduxProvider>
			</Elements>
		</StripeProvider>
	</ErrorBoundary>,
	document.getElementById("sno-app"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
