import * as React from "react";
import classNames from "classnames";
import "./_alert-box.scss";

interface IAlertBoxProps {
	className?: string;
	alertMessage?: any;
}

const AlertBox: React.FunctionComponent<IAlertBoxProps> = (props) => {
	const alertStyle = classNames("alert-box alert-box" + props.className);
	return (
		<div className={alertStyle} role="alert">
			<p>{props.alertMessage}</p>
		</div>
	);
};

export default AlertBox;
