export const AuthAction = {
	LOGIN: "auth:login",
	LOGOUT: "auth:logout",
};

export function login(token: string) {
	return {
		type: AuthAction.LOGIN,
		payload: token,
	};
}

export function logout() {
	return {
		type: AuthAction.LOGOUT,
	};
}
