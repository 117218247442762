import "./_ordersummary.scss";
import * as React from "react";
import { Only } from "../";
import { Orders } from "@sno_oslo/browser-utils";
import { Icon, IconType } from "../icon";
import { connect } from "react-redux";
import { getOnline } from "../../store/selectors/app";
import { logout } from "../../store/actions/auth";
import { getPriceSummary } from "../../store/selectors/orders";
import Currency from "../currency";

const { OrderManager } = Orders;

const OrderSummary = (props: any) => {
	const [summaryExpanded, setSummaryExpanded] = React.useState(false || props.expanded);
	const { orderSummary } = props;
	return (
		<Only if={orderSummary.lines.length >= 1 || props.expanded}>
			<div className="c-ordersummary">
				<h3>Oppsummering</h3>
				<Only if={!props.expanded}>
					<button
						className="c-ordersummary--expandbutton"
						onClick={() => {
							setSummaryExpanded(!summaryExpanded);
						}}
					>
						{summaryExpanded ? "Skjul" : "Se hele"}
					</button>
				</Only>

				<table className="c-ordersummary--ordertable">
					<tbody>
						{summaryExpanded
							? orderSummary.lines.map((orderLine: any) => {
									return (
										<React.Fragment key={orderLine.id}>
											<tr className="c-ordersummary--orderline">
												<td className="c-ordersummary--name">{orderLine.productName}</td>
												<td className="c-ordersummary--orderlineamount">
													<Currency value={orderLine.standardPrice} />
												</td>
												<td className="c-ordersummary--removeline">
													<button
														onClick={() => {
															if (orderSummary.lines.length <= 1) {
																OrderManager.removeProduct(orderLine.productId);
																props.history.push({
																	pathname: "/products/buy",
																	state: { direction: null },
																});
															} else {
																OrderManager.removeProduct(orderLine.productId);
															}
														}}
													>
														<Icon type={IconType.X} block />
													</button>
												</td>
											</tr>
											{orderLine.couponUsed ? (
												<tr className="c-ordersummary--coupon">
													<td className="c-ordersummary--name">{orderLine.couponUsed}</td>
													<td className="c-ordersummary--coupon--orderlineamount">
														<Currency
															value={(orderLine.standardPrice - orderLine.price) * -1}
														/>
													</td>
												</tr>
											) : null}
											{orderLine.addons.length >= 1
												? orderLine.addons.map((addon: any) => {
														return (
															<tr key={orderLine.id} className="c-ordersummary--addon">
																<td className="c-ordersummary--name" colSpan={4}>
																	Garderobe med dusj
																</td>
																<td className="c-ordersummary--addon--orderlineamount">
																	<Currency value={addon.price} />
																</td>
															</tr>
														);
														// tslint:disable-next-line: indent
												  })
												: null}
										</React.Fragment>
									);
									// tslint:disable-next-line: indent
							  })
							: null}
					</tbody>
					<tfoot>
						<tr className="c-ordersummary--sum">
							<td colSpan={4}>
								Totalt{" "}
								<p>
									<Currency value={orderSummary.sum} />
								</p>
							</td>
						</tr>
					</tfoot>
				</table>
			</div>
		</Only>
	);
};

// export default OrderSummary;
export default connect(
	(state) => {
		return {
			online: getOnline(state),
			orderSummary: getPriceSummary(state),
		};
	},
	(dispatch) => {
		return {
			logout: () => dispatch(logout()),
		};
	},
	null,
)(OrderSummary);
