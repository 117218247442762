export const UserAction = {
	SUCCESS: "user:success",
	LOADING: "user:loading",
	LOADED: "user:loaded",
	ERROR: "user:error",
	UPDATE_CONSENT: "user:update-consent",
};

export function success(response: any) {
	return {
		type: UserAction.SUCCESS,
		payload: response,
	};
}

export function updateConsent(consent: any) {
	return {
		type: UserAction.UPDATE_CONSENT,
		payload: consent,
	};
}

export function loaded() {
	return {
		type: UserAction.LOADED,
	};
}

export function loading() {
	return {
		type: UserAction.LOADING,
	};
}

export function error(err: any) {
	return {
		type: UserAction.ERROR,
		payload: err,
	};
}
