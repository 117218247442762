import * as React from "react";
import "./_loader.scss";

const Loader = (props: any) => {
	return (
		<div className="loader">
			<div />
			<div />
			<div />
			<div />
		</div>
	);
};

export default Loader;
