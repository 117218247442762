import { AppAction } from "../actions/creators/app";
import LocalStorage from "../LocalStorage";
import Config from "../Config";

const init = {
	online: navigator.onLine,
	navigationVisible: true,
	currentPageAllowsNavigation: true,
	version: process.env.REACT_APP_VERSION,
	previousVersion: LocalStorage.get(Config.STORAGE_PREVIOUS_APP_VERSION),
};

LocalStorage.set(Config.STORAGE_PREVIOUS_APP_VERSION, process.env.REACT_APP_VERSION);

export default function(state = init, { type, payload }: { type: string; payload?: any }) {
	switch (type) {
		case AppAction.SHOW_NAVIGATION:
			return {
				...state,
				navigationVisible: true,
			};
		case AppAction.HIDE_NAVIGATION:
			return {
				...state,
				navigationVisible: false,
			};
		case AppAction.SET_CURRENT_PAGE_ALLOWS_NAVIGATION:
			return {
				...state,
				currentPageAllowsNavigation: payload,
			};
		case AppAction.SET_ONLINE_STATUS:
			return {
				...state,
				online: payload,
			};
		default:
			return state;
	}
}
