import * as React from "react";
import "./_checkbox.scss";

const Checkbox = (props: any) => {
	return (
		<div className="c-checkbox">
			<input type="checkbox" checked={props.checked} readOnly onClick={props.onClick} />
			<span className="c-checkbox--checkmark" />
		</div>
	);
};
export default Checkbox;
