import * as React from "react";
import { connect } from "react-redux";
import { getUser } from "../../../store/selectors/user";
import { Page, Container, ScrollView, PageHeader } from "../../../components";

export function OrderHistory(props: any) {
	const backHandler = () => {
		props.history.push({ pathname: "/user/profile", state: { direction: "left" } });
	};

	const closeHandler = () => {
		props.history.push({ pathname: "/user/profile", state: { direction: null } });
	};

	return (
		<Page allowsNavigation={false}>
			<PageHeader closeHandler={closeHandler} backHandler={backHandler} backText="Profil" />
			<ScrollView>
				<Container>
					<p>Order history</p>
				</Container>
			</ScrollView>
		</Page>
	);
}

export default connect((state) => {
	return {
		user: getUser(state),
	};
})(OrderHistory);
