import * as userActions from "./creators/user";
import { Fetch } from "../../store/Fetch";
import LocalStorage from "../../store/LocalStorage";
import Config from "../../store/Config";

export function getUser(): any {
	return async (dispatch: (type: any) => any) => {
		try {
			dispatch(userActions.loading());
			const user = await Fetch.get("/user/{{env}}/?locale");
			LocalStorage.set(Config.STORAGE_USER, user);

			dispatch(userActions.success(user));
		} catch (e) {
			dispatch(userActions.error(e));
		} finally {
			dispatch(userActions.loaded());
		}
	};
}

export function updateUser(): any {
	return async (dispatch: (type: any) => any) => {
		try {
			dispatch(userActions.loading());

			const user = await Fetch.get("/user/{{env}}/?locale");

			LocalStorage.set(Config.STORAGE_USER, user);

			dispatch(userActions.success(user));
		} catch (e) {
			dispatch(userActions.error(e));
		} finally {
			dispatch(userActions.loaded());
		}
	};
}
