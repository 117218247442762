import Config from "./Config";
import { ResponseError } from "./FetchResponseError";

const HttpMethod = {
	GET: "GET",
	POST: "POST",
	PUT: "PUT",
	DELETE: "DELETE",
	PATCH: "PATCH",
};

export class Fetch {
	private static headers: any = {
		"Content-Type": "application/json",
		Accept: "application/json",
	};
	private static requestMiddleware: any = [];
	private static responseMiddleware: any = [];

	public static addGlobalHeader(name: string, value: any) {
		if (!this.headers[name]) {
			this.headers[name] = value;
		} else {
			throw new Error(`Header ${name} already exists with value == ${this.headers[name]}`);
		}
	}

	public static addResponseMiddleware(callback: (response: Response) => void) {
		this.responseMiddleware.push(callback);
	}

	public static addRequestMiddleware(callback: (response: Request) => void) {
		this.requestMiddleware.push(callback);
	}

	public static get(url: string, options?: any) {
		return this.makeRequest(url, HttpMethod.GET, undefined, options);
	}

	public static post(url: string, body: any, options?: any) {
		return this.makeRequest(url, HttpMethod.POST, body, options);
	}

	public static put(url: string, body: any, options?: any) {
		return this.makeRequest(url, HttpMethod.PUT, body, options);
	}

	public static patch(url: string, body: any, options?: any) {
		return this.makeRequest(url, HttpMethod.PATCH, body, options);
	}

	public static delete(url: string, body?: any, options?: any) {
		return this.makeRequest(url, HttpMethod.DELETE, body, options);
	}

	private static runMiddleware<T>(middlewares: any, object: T): T {
		// run all registered middleware
		for (const middleware of middlewares) {
			middleware(object);
		}

		return object;
	}

	private static async makeRequest(url: string, method: string, body?: any, options: any = {}) {
		// build request, this gets sent to the request middleware
		const request = new Request(this.buildUrl(url), {
			...options,
			method,
			headers: new Headers({ ...options.headers, ...this.headers }),
			body: method !== HttpMethod.GET && body ? JSON.stringify(body) : undefined,
		});
		// run middleware first before fetch (request), and then after fetch (response)
		const response = this.runMiddleware<Response>(
			this.responseMiddleware,
			await fetch(this.runMiddleware<Request>(this.requestMiddleware, request)),
		);

		if (response.ok) {
			// we assume for now we only deal with json
			return response.json();
		} else {
			throw new ResponseError("Invalid response code", response);
		}
	}

	private static buildUrl(url: string) {
		// Fetch can auto replace patterns with approps values
		// useful for env params, like: /products/{{env}}/whatever
		// could also be used for stuff like userId, etc
		const patterns = [{ pattern: "env", value: Config.ENV }];

		for (const { pattern, value } of patterns) {
			url = url.replace(`{{${pattern}}}`, value);
		}

		// if url == absolute, second param will be ignored,
		// otherwise url will default to rest api base url
		return new URL(url, Config.REST_API_URL).href;
	}
}
