import * as React from "react";
import "./_box.scss";

interface IBoxProps {
	boxHeader?: string;
}

const Box: React.FunctionComponent<IBoxProps> = (props) => {
	return (
		<div className="c-box">
			<h2>{props.boxHeader} </h2>
		</div>
	);
};

export default Box;
