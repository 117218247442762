export class ResponseError extends Error {
	response: Response;
	status: number;

	constructor(message: string, response: Response) {
		super(message);

		this.response = response;
		this.status = response.status;
	}
}
