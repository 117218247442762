import "./_pageheader.scss";
import * as React from "react";
import { Icon, IconType } from "../icon";
import { Touchable, Only } from "../";

const PageHeader = (props: any) => {
	return (
		<header className="c-pageheader">
			<Only if={props.backHandler}>
				<div className="c-pageheader--backbutton">
					<Touchable>
						<button onClick={props.backHandler}>
							<Icon type={IconType.ARROW_LEFT} /> {props.backText ? props.backText : "Tilbake"}
						</button>
					</Touchable>
				</div>
			</Only>
			<div className="c-pageheader--header">{props.title ? <h3>{props.title}</h3> : props.children}</div>
			<Only if={props.closeHandler}>
				<div className="c-pageheader--closebutton">
					<Touchable>
						<button onClick={props.closeHandler}>
							<Icon type={IconType.X} />
						</button>
					</Touchable>
				</div>
			</Only>
		</header>
	);
};

export default PageHeader;
