import * as React from "react";
import classNames from "classnames";
import "./_alert.scss";

interface IAlertProps {
	className?: string;
	alertMessage?: string;
}

const Alert: React.FunctionComponent<IAlertProps> = (props) => {
	const alertStyle = classNames("alert", props.className);
	return <p className={alertStyle}>{props.alertMessage}</p>;
};

export default Alert;
