import * as React from "react";

const Currency = (props: any) => {
	const newValue = props.value
		.toFixed(2)
		.toString()
		.replace(".", ",")
		.replace(",00", "");
	return <span> {newValue} kr </span>;
};

export default Currency;
