export function getNavigationVisible(state: any) {
	return state.app.navigationVisible;
}

export function getCurrentPageAllowsNavigation(state: any) {
	return state.app.currentPageAllowsNavigation;
}

export function getOnline(state: any) {
	return state.app.online;
}
