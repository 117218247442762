import * as React from "react";

import { Page, Container, ScrollView, Touchable } from "../components";
import { NavLink } from "react-router-dom";

export default function Buy(props: any) {
	return (
		<Page>
			<ScrollView>
				<Container blue fillHeight>
					<h1 className="h1">Fant ikke siden</h1>
					<p>404 {props.location.pathname}</p>
					<Touchable>
						<NavLink to={{ pathname: "/products/buy", state: { direction: null } }}>
							<span>Tilbake</span>
						</NavLink>
					</Touchable>
				</Container>
			</ScrollView>
		</Page>
	);
}
