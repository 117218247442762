import "./toggle.scss";

import * as React from "react";

export default function Toggle({
	value = false,
	onChange = () => {
		// ..
	},
}: any) {
	return (
		<label className="toggle">
			<input type="checkbox" checked={value} value={value} onChange={onChange} />
			<span className="slider" />
		</label>
	);
}
