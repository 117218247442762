import * as React from "react";
import { connect } from "react-redux";
import { getUser } from "../../../../store/actions/user";
import {
	Page,
	Container,
	ScrollView,
	ContainerHeader,
	ButtonGroup,
	Button,
	Alert,
	BackLink,
} from "../../../../components";

import "./_page-couponcode.scss";
import { Fetch } from "../../../../store/Fetch";

export function CouponCode(props: any) {
	const couponCode = "";
	const [inputValue, setInputValue] = React.useState(couponCode);
	const [loading, setLoading] = React.useState(false);
	const [showAlert, setShowAlert] = React.useState(false);

	const setInputValueHandler = (event: any) => {
		setInputValue(event.target.value);
	};

	const onFocus = () => {
		setShowAlert(false);
	};

	async function couponCodeHandler() {
		setLoading(true);
		const coupon = {
			couponId: inputValue,
		};
		try {
			await Fetch.post("/user/{{env}}/coupon", coupon);
			props.getUser();
			props.history.push("/user/profile/coupons");
		} catch (error) {
			setShowAlert(true);
			setLoading(false);
		}
	}

	return (
		<Page className="page-coupon-code" popup>
			<ScrollView>
				<Container>
					<BackLink to="/user/profile/coupons" />
					<ContainerHeader left>
						<h2>Legg inn avtalekode</h2>
					</ContainerHeader>
					<form>
						<label className="c-label">Avtalekode</label>
						<input
							onFocus={onFocus}
							value={inputValue}
							onChange={setInputValueHandler}
							className={showAlert ? "alert--error c-input" : "c-input"}
							placeholder="Skriv inn koden"
						/>
						{showAlert ? (
							<Alert className="alert--error-text" alertMessage="Koden finnes ikke"></Alert>
						) : null}
						<ButtonGroup>
							<Button onClick={couponCodeHandler} loading={loading}>
								Send inn kode
							</Button>
						</ButtonGroup>
					</form>
				</Container>
			</ScrollView>
		</Page>
	);
}

export default connect(
	(state) => {
		return {};
	},
	(dispatch) => {
		return {
			getUser: () => dispatch(getUser()),
		};
	},
)(CouponCode);
