import "./_page-buy.scss";
import { connect } from "react-redux";
import * as React from "react";
import {
	Page,
	Container,
	ContainerHeader,
	ScrollView,
	PopupLink,
	ButtonGroup,
	Button,
	OnlyOffline,
	OnlyOnline,
} from "../../components";
import { Icon, IconType } from "../../components/icon";
import { getOnline } from "../../store/selectors/app";

export function Buy(props: any) {
	return (
		<Page className="page-buy">
			<ScrollView>
				<Container>
					<ContainerHeader>
						<h2>Kjøp pass</h2>
					</ContainerHeader>
					<OnlyOffline>
						<p style={{ textAlign: "center" }}>Du må være tilkoblet internett for å kjøpe pass</p>
					</OnlyOffline>
					<OnlyOnline>
						<ul>
							<li>
								<PopupLink to={"/products/buy/daypass"} open>
									<>
										<Icon type={IconType.LOGO} block className="c-logosymbol" />
										<h3>Dagspass</h3>
									</>
								</PopupLink>
							</li>
							<li>
								<PopupLink to={"/products/buy/subscription"} open>
									<>
										<Icon
											type={IconType.LOGO}
											block
											className="c-logosymbol c-logosymbol--inverse"
										/>

										<h3>Medlemskap</h3>
									</>
								</PopupLink>
							</li>
						</ul>
						<ButtonGroup>
							<PopupLink className="page-buy--wide-link" open to="/products/redeem">
								<Button wide secondary grey>
									Bruk hentekode
								</Button>
							</PopupLink>
						</ButtonGroup>
					</OnlyOnline>
				</Container>
			</ScrollView>
		</Page>
	);
}

export default connect((state) => {
	return {
		online: getOnline(state),
	};
})(Buy);
