import * as React from "react";
import Link from "../link";
import css from "classnames";
import { Icon, IconType } from "../icon";

export default function ForwardLink({
	to,
	children,
	button,
	wide,
	disabled,
	arrow,
	className,
	grey,
	onClick = () => {
		// ..
	},
}: any) {
	return (
		<Link
			disabled={disabled}
			onClick={onClick}
			className={css(className, { "c-button": button, "c-button--wide": wide, "c-button--grey": grey })}
			to={{ pathname: to, state: { direction: "right" } }}
		>
			{children} {arrow ? <Icon type={IconType.ARROW_RIGHT} /> : null}
		</Link>
	);
}
