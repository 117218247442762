import * as React from "react";
import { connect } from "react-redux";
import { getUser } from "../../../store/selectors/user";
import { Page, Container, ScrollView, ContainerHeader, BackLink } from "../../../components";

import "./_page-delete.scss";

export function Delete(props: any) {
	return (
		<Page allowsNavigation={false} className="page-delete">
			<ScrollView>
				<Container>
					<BackLink to="/user/profile" backText="Profil" />
					<ContainerHeader left>
						<h2>Slett meg</h2>
					</ContainerHeader>
					<p>
						Dersom du ønsker å slette profilen din, og alle tilhørende data, kan du sende oss en e-post så
						håndterer vi det for deg.
					</p>
					<p>
						Send e-post til{" "}
						<a className="page-delete--mail" href="mailto:kontakt@snooslo.no">
							kontakt@snooslo.no
						</a>
					</p>
				</Container>
			</ScrollView>
		</Page>
	);
}

export default connect((state) => {
	return {
		user: getUser(state),
	};
})(Delete);
