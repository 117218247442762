import * as React from "react";
import { Route } from "react-router-dom";

import MyProducts from "../pages/my-products";
import ProductDetails from "../pages/my-products/details";
import Transfer from "../pages/my-products/transfer";
import Redeem from "../pages/my-products/redeem";
import Buy from "../pages/buy";
import BuyDaypass from "../pages/buy/daypass";
import BuySubscription from "../pages/buy/subscription";
import BuySubscriptionDuration from "../pages/buy/subscription/duration";
import Terms from "../pages/buy/terms";
import BuyDaypassQuantity from "../pages/buy/daypass/quantity";
import Payment from "../pages/buy/payment";
import Receipt from "../pages/buy/receipt";
import Summary from "../pages/buy/summary";
import Profile from "../pages/profile/";
import Coupons from "../pages/profile/coupons";
import CouponCode from "../pages/profile/coupons/add-coupon-code";
import Cards from "../pages/profile/cards";
import AddCard from "../pages/profile/cards/add-card";
import OrderHistory from "../pages/profile/orderhistory";
import Delete from "../pages/profile/delete";
import EditCard from "../pages/my-products/edit-card";

import Debug from "../pages/debug";
import NotFound from "../pages/NotFound";

let i = 0;

export const INITIAL_ROUTE = "/user/my-products";
export const routes = [
	<Route exact path="/products/buy" component={Buy} key={i++} />,
	<Route exact path="/products/buy/daypass" component={BuyDaypass} key={i++} />,
	<Route exact path="/products/buy/daypass/quantity/:type?" component={BuyDaypassQuantity} key={i++} />,
	<Route exact path="/products/buy/terms/:type?" component={Terms} key={i++} />,
	<Route exact path="/products/buy/subscription" component={BuySubscription} key={i++} />,
	<Route exact path="/products/buy/subscription/type/:type?" component={BuySubscriptionDuration} key={i++} />,
	<Route exact path="/products/buy/summary/:type?" component={Summary} key={i++} />,
	<Route exact path="/products/buy/payment/:type?" component={Payment} key={i++} />,
	<Route exact path="/products/buy/receipt" component={Receipt} key={i++} />,
	<Route exact path="/user/my-products" component={MyProducts} key={i++} />,
	<Route exact path="/user/my-products/editcard" component={EditCard} key={i++} />,
	<Route exact path="/user/my-products/:productid" component={ProductDetails} key={i++} />,
	<Route exact path="/products/redeem" component={Redeem} key={i++} />,
	<Route exact path="/user/my-products/transfer/:productid/:redeemcode" component={Transfer} key={i++} />,
	<Route exact path="/user/profile" component={Profile} key={i++} />,
	<Route exact path="/user/profile/orderhistory" component={OrderHistory} key={i++} />,
	<Route exact path="/user/profile/cards" component={Cards} key={i++} />,
	<Route exact path="/user/profile/addcard" component={AddCard} key={i++} />,
	<Route exact path="/user/profile/coupons" component={Coupons} key={i++} />,
	<Route exact path="/user/profile/couponcode" component={CouponCode} key={i++} />,
	<Route exact path="/user/profile/delete" component={Delete} key={i++} />,

	// 404 and debug
	<Route exact path="/debug" component={Debug} key={i++} />,
	<Route component={NotFound} key={i++} />,
];
