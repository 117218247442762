export default class NativeBridge {
	static listeners: any = [];

	// broadcast to native
	static broadcastNative(event: string, data?: any) {
		this._log(event, data, "native");

		const message = {
			event,
			data: data ? data : {},
		};

		// is there another way to do this, the same for both platforms? postMessage?
		if ((window as any).Android && (window as any).Android.performNativeAction) {
			(window as any).Android.performNativeAction(JSON.stringify(message));
		} else if (
			(window as any).webkit &&
			(window as any).webkit.messageHandlers &&
			(window as any).webkit.messageHandlers.bridgeMessageHandler
		) {
			(window as any).webkit.messageHandlers.bridgeMessageHandler.postMessage(JSON.stringify(message));
		} else {
			window.postMessage(JSON.stringify(message), "*");
		}
	}

	// broadcast to webview
	static broadcastWeb(event: string, data?: any) {
		try {
			data = data ? JSON.parse(data) : data;
		} catch (e) {
			// do nothing
		}

		for (const listener of this.listeners) {
			if (listener.event === event) {
				listener.callback(data);

				if (listener.once) {
					this.listeners = this.listeners.filter((i: any) => i !== listener);
				}
			}
		}

		this._log(event, data, "web");
	}

	// webview listen
	static listen(event: string, callback: any, once = false) {
		const listener = {
			event,
			callback,
			once,
		};

		this.listeners.push(listener);

		return listener;
	}

	static unlisten(event: string, listener: any) {
		this.listeners = this.listeners.filter((i: any) => !(i.event === event && i.callback === listener));
	}

	static _log(event: string, data: any, type: any) {
		// tslint:disable-next-line: max-line-length
		// tslint:disable-next-line: no-console
		console.info(`%c@${type} %c${event}`, "color: blue", "font-weight: bold; color: blue", data);
	}
}

// this export required for native wrapper
(window as any).NativeBridge = NativeBridge;
