import * as React from "react";
import { connect } from "react-redux";
import { Page, Container, ScrollView, ContainerHeader, Button, BackLink } from "../../../../components";
import { CardNumberElement, CardExpiryElement, CardCVCElement, injectStripe } from "react-stripe-elements";

import "./_page-add-card.scss";
import { Fetch } from "../../../../store/Fetch";
import { getCards } from "../../../../store/actions/cards";

export function AddCard(props: any) {
	const [loading, setLoading] = React.useState(false);
	async function addPaymentMethod() {
		setLoading(true);
		props.stripe.createPaymentMethod("card").then(async ({ paymentMethod, error }: any) => {
			if (error) {
				throw error;
			}

			const data = {
				paymentMethodId: paymentMethod.id,
			};

			await Fetch.post("/user/{{env}}/payment-methods", data);
			props.getCards();
			props.history.push("/user/profile/cards");
		});
	}

	return (
		<Page allowsNavigation={false} className="page-add-card">
			<ScrollView>
				<Container>
					<BackLink to="/user/profile/cards" />
					<ContainerHeader left>
						<h2>Legg til betalingskort</h2>
					</ContainerHeader>
					<label className="c-label">
						Kortnummer
						<CardNumberElement className="c-input" />
					</label>
					<div className="page-add-card--form">
						<label className="c-label">
							Utløpsdato
							<CardExpiryElement className="c-input" />
						</label>
						<label className="c-label">
							CVC
							<CardCVCElement className="c-input" />
						</label>
					</div>
					<Button wide onClick={addPaymentMethod} loading={loading}>
						Legg til kort
					</Button>
				</Container>
			</ScrollView>
		</Page>
	);
}

export default connect(
	(state) => {
		return {};
	},
	(dispatch) => {
		return {
			getCards: () => dispatch(getCards()),
		};
	},
)(injectStripe(AddCard));
