export function getUser(state: any) {
	return state.user.data.user;
}

export function getUserLoading(state: any) {
	return state.user.loading;
}

export function getCoupons(state: any) {
	return state.user.data.user.coupons;
}

export function getCouponsCount(state: any) {
	const couponsCount = (state.user.data.user.coupons || []).length;
	return couponsCount;
}

export function getAllAccessProducts(state: any) {
	const allAccessProducts = (state.user.data.accessProducts.ownerPasses || []).concat(
		state.user.data.accessProducts.userPasses || [],
	);
	return allAccessProducts;
}

export function getSingleAccessProduct(state: any, productId: string) {
	const allAccessProducts = (state.user.data.accessProducts.ownerPasses || []).concat(
		state.user.data.accessProducts.userPasses || [],
	);
	const product = allAccessProducts.filter((prod: any) => prod.id === productId)[0];

	return product;
}
