import "./title-header.scss";

import * as React from "react";
import { Only, BackLink, Header } from "..";
import { Icon, IconType } from "../icon";

export default function TitleHeader(props: any) {
	return (
		<Header>
			<div className="title-header">
				<div className="title-header__left">
					<Only if={props.backUrl}>
						<BackLink to={props.backUrl}>
							<Icon type={IconType.ARROW_LEFT} />
							<span className="visually-hidden">Tilbake</span>
						</BackLink>
					</Only>
					<Only if={!props.backUrl && props.leftButton}>{props.leftButton}</Only>
				</div>
				<h1 className="title-header__title">{props.title}</h1>
				<div className="title-header__right">
					<Only if={props.rightButton}>{props.rightButton}</Only>
				</div>
			</div>
		</Header>
	);
}
