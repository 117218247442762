import * as React from "react";
import Link from "../link";

export default function PopupLink({
	to,
	children,
	open,
	close,
	className,
	disabled,
}: {
	to: string;
	open?: any;
	close?: any;
	className?: string;
	disabled?: boolean;
	children: any;
}) {
	let direction;

	if (open) {
		direction = "bottom";
	}

	if (close) {
		direction = "top";
	}

	return (
		<Link disabled={disabled} className={className} to={{ pathname: to, state: { direction } }}>
			{children}
		</Link>
	);
}
