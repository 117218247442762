import "./page.scss";

import * as React from "react";
import { connect } from "react-redux";
import { getNavigationVisible } from "../../store/selectors/app";
import { showNavigation, hideNavigation, setCurrentPageAllowsNavigation } from "../../store/actions/app";
import css from "classnames";
import { Icon, IconType } from "../icon";

// File imports
// tslint:disable-next-line: no-var-requires
const loadervideo = require("../../assets/videos/loader.mp4");

interface IProps {
	allowsNavigation?: boolean;
	popup?: boolean;
	setCurrentPageAllowsNavigation?: (allowsNavigation: boolean) => void;
	showNavigation?: () => void;
	hideNavigation?: () => void;
	navigationVisible?: boolean;
	className?: string;
	closeHandler?: () => void;
	blue?: string;
	standalone?: boolean;
	loading?: boolean;
	loadText?: string;
	children?: React.ReactNode;
}

class Page extends React.Component<IProps> {
	constructor(props: IProps) {
		super(props);
	}

	componentWillMount() {
		// if popup == true, allowsNavigation is auto false
		const allowsNavigation = this.props.allowsNavigation && !this.props.popup;

		// store current pages setting
		if (this.props.navigationVisible !== allowsNavigation) {
			this.props.setCurrentPageAllowsNavigation(allowsNavigation);
		}

		if (allowsNavigation && !this.props.navigationVisible) {
			showNavigation();
		}

		if (!allowsNavigation && this.props.navigationVisible) {
			hideNavigation();
		}
	}
	render() {
		const { children, popup, blue, standalone, loading, loadText, closeHandler } = this.props;

		return (
			<>
				<Loader loading={loading} loadText={loadText}>
					<div
						className={css(
							"page",
							{
								"page--popup": popup,
								"page--blue": blue,
								"page--standalone": standalone,
								"page--loading": loading,
							},
							this.props.className,
						)}
					>
						{this.props.closeHandler ? (
							<button className="page--closebutton" onClick={closeHandler}>
								<Icon type={IconType.X} />
							</button>
						) : null}

						{children}
					</div>
				</Loader>
			</>
		);
	}
}

const Loader = (props: any) => {
	if (props.loading) {
		return (
			<div className="page--loading">
				<div className="overlay">
					<div className="videocontainer">
						<video autoPlay playsInline muted loop controls={false} width="100px">
							<source src={loadervideo} type="video/mp4" />
						</video>
						{props.loadText ? props.loadText : "Laster..."}
					</div>
				</div>
				{props.children}
			</div>
		);
	} else {
		return <>{props.children}</>;
	}
};

export default connect(
	(state) => {
		return {
			navigationVisible: getNavigationVisible(state),
		};
	},
	(dispatch) => {
		return {
			showNavigation: () => dispatch(showNavigation()),
			hideNavigation: () => dispatch(hideNavigation()),
			setCurrentPageAllowsNavigation: (state: boolean) => dispatch(setCurrentPageAllowsNavigation(state)),
		};
	},
)(Page);
