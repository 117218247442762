import * as React from "react";

import { connect } from "react-redux";
import { Page, Container, ScrollView, ContainerHeader, Button, PopupLink } from "../../../components";
import { getCards, getNumberOfCards } from "../../../store/selectors/cards";

import { Icon, IconType } from "../../../components/icon";
import { Fetch } from "../../../store/Fetch";
import { injectStripe } from "react-stripe-elements";

import "../../profile/cards/_page-cards.scss";

export function EditCard(props: any) {
	const [loading, setLoading] = React.useState(false);
	const [selectedCard, setSelectedCard] = React.useState(null);

	// This is to just get the IDs from the cards
	const cardIDs = props.cards.cards.map((card: any) => {
		return card.id;
	});

	// This is to style the currently used card
	const activeCard = cardIDs.find((target: any) => {
		return target === props.location.state.stripePaymentMethodId;
	});

	// This is to not fire the newPaymentmethod function before the selectedCard state has updated
	React.useEffect(() => {
		if (selectedCard == null) {
			return;
		}
		newPaymentmethod();
		// eslint-disable-next-line
	}, [selectedCard]);

	async function newPaymentmethod() {
		setLoading(true);
		const data = {
			paymentMethodId: selectedCard,
		};
		try {
			await Fetch.patch("/user/{{env}}/access-products/" + props.location.state.qrCode + "/paymentmethod", data);
			props.history.goBack();
		} catch (error) {
			// tslint:disable-next-line: no-console
			console.error(error);
			setLoading(false);
		}
	}

	return (
		<Page
			allowsNavigation={false}
			className="page-cards"
			closeHandler={() => {
				props.history.goBack();
			}}
		>
			<ScrollView>
				<Container>
					{loading ? (
						<div className="animate">
							<object
								aria-label="Loading animation"
								data="https://images.ctfassets.net/4gvqn75my5tn/5Tckpp33mkRhJq5zLFlcuR/9a97d853d9460c039dbf97c50dc8a6cb/image__4_.svg"
							></object>
						</div>
					) : (
						<>
							<ContainerHeader left>
								<h2>Endre betalingskort</h2>
							</ContainerHeader>

							{props.numberOfCards > 0 ? (
								<>
									<h3>
										{props.numberOfCards > 1 ? "Lagrede betalingskort" : "Lagret betalingskort"}
									</h3>
									{props.cards.cards.map((card: any) => {
										return (
											<div
												key={card.id}
												className={activeCard === card.id ? "c-card c-card--active" : "c-card"}
												onClick={() => setSelectedCard(card.id)}
											>
												<div>
													<Icon
														type={
															card.brand === "visa"
																? "visa"
																: card.brand === "mastercard"
																? "mastercard"
																: "credit-card"
														}
													></Icon>
													<p>**** **** **** **** {card.last4}</p>
													{activeCard === card.id ? (
														<div className="c-card--active-container">
															<Icon type={IconType.CHECKMARK_HEAVY} block />
														</div>
													) : null}
												</div>
												<p>
													Gyldig til {card.expMonth}/{card.expYear}
												</p>
											</div>
										);
									})}
								</>
							) : (
								<p>Du har ingen lagrede kort, vil du legge til et?</p>
							)}

							<div>
								<p>Finner du ikke kortet ditt her? Legg til et nytt kort på profilen din først</p>
								<PopupLink className="c-button--wide" open to="/user/profile/addcard">
									<Button thin grey>
										Legg til nytt kort
									</Button>
								</PopupLink>
							</div>
						</>
					)}
				</Container>
			</ScrollView>
		</Page>
	);
}

export default connect((state) => {
	return {
		cards: getCards(state),
		numberOfCards: getNumberOfCards(state),
	};
})(injectStripe(EditCard));
