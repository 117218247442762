import * as cardsActions from "./creators/cards";
import { Fetch } from "../../store/Fetch";

export function getCards(): any {
	return async (dispatch: (type: any) => any) => {
		try {
			dispatch(cardsActions.loading());

			const cards = await Fetch.get("/user/{{env}}/payment-methods");

			dispatch(cardsActions.success(cards));
		} catch (e) {
			dispatch(cardsActions.error(e));
		} finally {
			dispatch(cardsActions.loaded());
		}
	};
}
