export const CardsAction = {
	SUCCESS: "cards:success",
	LOADING: "cards:loading",
	LOADED: "cards:loaded",
	ERROR: "cards:error",
};

export function success(response: any) {
	return {
		type: CardsAction.SUCCESS,
		payload: response,
	};
}

export function loaded() {
	return {
		type: CardsAction.LOADED,
	};
}

export function loading() {
	return {
		type: CardsAction.LOADING,
	};
}

export function error(err: any) {
	return {
		type: CardsAction.ERROR,
		payload: err,
	};
}
