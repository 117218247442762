import * as React from "react";
import "../../_icon.scss";

const Baby = ({ style = {}, fill = "", width = "100%", className = "", viewBox = "0 0 24 24" }) => (
	<svg
		width={width}
		style={style}
		height={width}
		fill={fill}
		viewBox={viewBox}
		xmlns="http://www.w3.org/2000/svg"
		className={`c-icon ${className || ""}`}
		xmlnsXlink="http://www.w3.org/1999/xlink"
	>
		<g xmlns="http://www.w3.org/2000/svg">
			<path
				fill={fill}
				d="M8.001,13.375c-1.121,0-2-0.714-2-1.625c0-0.276,0.224-0.5,0.5-0.5s0.5,0.224,0.5,0.5c0,0.339,0.458,0.625,1,0.625   c0.572,0,1-0.33,1-0.625c0-0.276,0.224-0.5,0.5-0.5s0.5,0.224,0.5,0.5C10.001,12.646,9.103,13.375,8.001,13.375z"
			/>
			<path
				fill={fill}
				d="M16,13.375c-1.103,0-2-0.729-2-1.625c0-0.276,0.224-0.5,0.5-0.5s0.5,0.224,0.5,0.5c0,0.295,0.428,0.625,1,0.625   c0.542,0,1-0.286,1-0.625c0-0.276,0.224-0.5,0.5-0.5s0.5,0.224,0.5,0.5C18,12.661,17.122,13.375,16,13.375z"
			/>
			<path
				fill={fill}
				d="M11.775,18.759c-0.976,0-1.891-0.435-2.509-1.193c-0.174-0.214-0.142-0.529,0.072-0.703   c0.089-0.073,0.201-0.113,0.316-0.113c0.151,0,0.292,0.067,0.388,0.184c0.427,0.524,1.06,0.825,1.737,0.825   c0.06,0,0.121-0.002,0.182-0.008c0.007,0,0.034-0.001,0.041-0.001c0.013,0,0.025,0,0.038,0.001   c0.065,0.005,0.127,0.008,0.189,0.008c0.673,0,1.305-0.301,1.732-0.825c0.096-0.117,0.237-0.184,0.388-0.184   c0.115,0,0.227,0.04,0.315,0.112c0.214,0.175,0.246,0.49,0.072,0.704c-0.618,0.758-1.533,1.193-2.511,1.193   c-0.074,0-0.149-0.002-0.224-0.008C11.925,18.757,11.85,18.759,11.775,18.759z"
			/>
			<path
				fill={fill}
				d="M12.001,22.75c-4.403-0.069-8.252-2.902-9.625-7.066c-1.116-0.238-2.003-1.088-2.283-2.204   c-0.372-1.484,0.437-3.007,1.853-3.538C2.763,5,6.964,1.354,11.99,1.25c0.001,0,0.039,0.001,0.047,0.002   c5.01,0.117,9.203,3.761,10.019,8.69C23.225,10.379,24,11.488,24,12.75c0,1.433-0.99,2.643-2.375,2.934   c-1.374,4.164-5.22,6.996-9.617,7.066c-0.001,0-0.003,0-0.004,0S12.002,22.75,12.001,22.75z M11.996,2.25   c-4.654,0.098-8.484,3.522-9.107,8.142c-0.028,0.204-0.174,0.368-0.374,0.418c-1.07,0.269-1.721,1.357-1.453,2.427   c0.205,0.818,0.907,1.422,1.746,1.504c0.203,0.02,0.373,0.159,0.431,0.355c1.161,3.916,4.686,6.59,8.769,6.654   c4.071-0.064,7.592-2.738,8.754-6.654c0.059-0.197,0.228-0.336,0.431-0.355c1.03-0.1,1.807-0.956,1.807-1.99   c0-0.919-0.623-1.717-1.514-1.94c-0.2-0.05-0.346-0.214-0.374-0.418c-0.496-3.678-3.106-6.704-6.599-7.748   c0.262,0.403,0.426,0.864,0.475,1.35c0.139,1.393-0.88,2.641-2.272,2.782c-0.073,0.007-0.146,0.011-0.218,0.011   c-0.494,0-0.959-0.166-1.344-0.48c-0.441-0.36-0.715-0.87-0.773-1.436c0-0.001,0-0.003,0-0.004   c-0.021-0.192-0.01-0.386,0.032-0.574c0.051-0.23,0.252-0.391,0.488-0.391c0.036,0,0.073,0.004,0.108,0.012   c0.131,0.029,0.242,0.107,0.314,0.22c0.072,0.113,0.095,0.247,0.066,0.377c-0.018,0.083-0.023,0.168-0.013,0.253   c0.032,0.309,0.177,0.579,0.411,0.77c0.204,0.167,0.45,0.255,0.712,0.255c0.039,0,0.078-0.002,0.117-0.006   c0.844-0.086,1.463-0.842,1.378-1.687C13.889,3.064,13.031,2.272,11.996,2.25z"
			/>
		</g>
	</svg>
);

export default Baby;
