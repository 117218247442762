import "./arrow-link.scss";

import * as React from "react";
import { Link } from "react-router-dom";
import Touchable from "../touchable";
import { Icon, IconType } from "../icon";

export default function ArrowLink({ to, direction = "right", title, pretitle }: any) {
	return (
		<Touchable>
			<Link className="c-arrow-link" to={{ pathname: to, state: { direction } }}>
				<div className="c-arrow-link--text">
					<h3 className="c-arrow-link--pretitle">{pretitle}</h3>
					<h4 className="c-arrow-link--title">{title}</h4>
				</div>
				<div className="c-arrow-link--arrow">
					<Icon type={IconType.CHEVRON_RIGHT} />
				</div>
			</Link>
		</Touchable>
	);
}
