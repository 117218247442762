import * as React from "react";
import Link from "../link";
import { Icon, IconType } from "../icon";

export default function BackLink({
	to,
	backText,
	backHandler,
	className,
	disabled,
	onClick = () => {
		/* .. */
	},
}: any) {
	return (
		<Link
			onClick={onClick}
			disabled={disabled}
			className={className}
			to={{ pathname: to, state: { direction: "left" } }}
		>
			<button onClick={backHandler}>
				<Icon type={IconType.ARROW_LEFT} /> {backText ? backText : null}
			</button>
		</Link>
	);
}
