import * as React from "react";
import { Page, Container, ScrollView, Button } from "../../components";
import { isDebug, setDebug } from "../../utils";
import { connect } from "react-redux";
import { logout } from "../../store/actions/auth";
import { getUser } from "../../store/actions/user";
import { getOnline } from "../../store/selectors/app";

const Debug = (props: any) => {
	const [error, setError] = React.useState(false);

	if (error) {
		throw new Error("It's Britney, bitch");
	}

	return (
		<Page>
			<ScrollView>
				<Container>
					<h1 className="h1">Debug</h1>

					<h2 className="h3">Super error</h2>
					<Button onClick={() => setError(true)}>Trigger fatal error</Button>

					<hr className="hr" />

					<h2 className="h3">Debug mode</h2>
					<p>Debug mode viser ekstra informasjon i enkelte deler av appen</p>
					<Button
						onClick={() => {
							setDebug(true);
						}}
					>
						Enter debug mode
					</Button>
					<Button
						onClick={() => {
							setDebug(false);
						}}
						outlined
					>
						Leave debug mode
					</Button>

					<p>{isDebug ? "du er i debug mode" : "du er ikke i debug mode"}</p>

					<hr className="hr" />

					<h2 className="h3">Slett midlertidige pass</h2>
					<p>Sletter midlertidige pass i localstorage</p>
					<Button
						onClick={() => {
							localStorage.removeItem("temporaryPurchasedProducts");
						}}
					>
						Slett
					</Button>

					<hr className="hr" />

					<h2 className="h3">Hent bruker på nytt</h2>
					<Button
						onClick={() => {
							props.getUser();
						}}
					>
						Hent bruker
					</Button>
				</Container>
			</ScrollView>
		</Page>
	);
};
export default connect(
	(state) => {
		return {
			online: getOnline(state),
		};
	},
	(dispatch) => {
		return {
			logout: () => dispatch(logout()),
			getUser: () => dispatch(getUser()),
		};
	},
)(Debug);
