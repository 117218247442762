import "./_page-transfer.scss";

import * as React from "react";
import { connect } from "react-redux";
import { Page, Container, ScrollView, ContainerHeader, Button, ButtonGroup } from "../../../components";

export function Transfer(props: any) {
	const redeemcode = props.match.params.redeemcode;
	const passType = props.location.state.passType;
	const userNameForPass = props.location.state.userNameForPass;

	return (
		<Page
			className="page-transfer"
			popup
			closeHandler={() => {
				props.history.goBack();
			}}
		>
			<ScrollView>
				<Container>
					<ContainerHeader left>
						<h2>{passType === "subscription" ? "Gi tilgang til medlemskap" : "Gi bort passet"}</h2>
					</ContainerHeader>
					{passType === "subscription" ? (
						<div className="page-transfer--description">
							<p>
								Den du gir tilgang til vil kunne åpne og lagre passet på sin SNØ-profil. Dersom de ikke
								har profil akkurat nå, kan de registrere seg for å se passet.{" "}
							</p>
							<p>
								Når du gir bort tilgang mister du retten til å bruke passet selv. Det er fremdeles du
								som eier og betaler for medlemskapet, så du vil fremdeles kunne se passet, og legge til
								eller fjerne tilleggstjenester når du ønsker.
							</p>
							<p className="page-transfer--userName">
								Navnet på medlemskapet blir <strong>{userNameForPass}</strong>
							</p>
						</div>
					) : (
						<>
							<p>Send passet til en venn på melding eller del hentekoden for å gi bort passet.</p>
							<p>
								Den du gir bort passet til må logge inn eller registrere seg for å se og bruke passet.
							</p>
						</>
					)}

					<ButtonGroup>
						<Button
							full
							onClick={() => {
								(window as any).webkit !== undefined
									? (window as any).webkit.messageHandlers.share.postMessage(
											`Hei! Her har du passet ditt: https://dev.snooslo.no/min-sno?redeemCode=${redeemcode}`,
											// tslint:disable-next-line: indent
									  )
									: // tslint:disable-next-line: no-console tslint:disable-next-line: indent
									  console.log("Ikke på appen");
							}}
						>
							Del passet
						</Button>
					</ButtonGroup>

					<div className="redeemcode">
						<p>Eller bruk hentekode:</p>
						<p>
							<strong>{redeemcode}</strong>
						</p>
					</div>
				</Container>
			</ScrollView>
		</Page>
	);
}

export default connect<any, any, any>((state: any) => {
	return {
		accessProducts: state.user.data ? state.user.data.accessProducts : [],
	};
})(Transfer);
