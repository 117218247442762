import { AuthAction } from "../actions/creators/auth";
import LocalStorage from "../LocalStorage";
import Config from "../Config";

interface IAuthState {
	token: string;
	loading: boolean;
	error: any;
}

const init: IAuthState = {
	token: LocalStorage.get(Config.STORAGE_TOKEN),
	loading: false,
	error: null,
};

export default function(state = init, { type, payload }: { type: string; payload?: any }): IAuthState {
	switch (type) {
		case AuthAction.LOGIN:
			return {
				...state,
				token: payload,
			};
		case AuthAction.LOGOUT:
			return {
				...state,
				token: null,
			};
		default:
			return state;
	}
}
