import "./button.scss";

import * as React from "react";
import Touchable from "../touchable";
import css from "classnames";

export default function Button({
	full,
	wide,
	plain,
	link,
	secondary,
	grey,
	outlined,
	children,
	className,
	thin,
	loading,
	square,
	submit = false,
	disabled = false,
	onClick = () => {
		// ..
	},
}: any) {
	return (
		<Touchable>
			<button
				onClick={onClick}
				className={
					plain
						? className
						: css("c-button", className, {
								"c-button--full": full,
								"c-button--wide": wide,
								"c-button--thin": thin,
								"c-button--plain": plain,
								"c-button--secondary": secondary,
								"c-button--grey": grey,
								"c-button--outlined": outlined,
								"c-button--loading": loading,
								"c-button--link": link,
								"c-button--square": square,
								"c-button--disabled": disabled,
								// tslint:disable-next-line: indent
						  })
				}
				type={submit ? "submit" : "button"}
			>
				{loading ? (
					<div className="c-button--loader">
						<div />
						<div />
						<div />
						<div />
					</div>
				) : (
					children
				)}
			</button>
		</Touchable>
	);
}
