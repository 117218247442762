import "./_page-receipt.scss";

import * as React from "react";
import { Page, Container, ScrollView, PageHeader, Button } from "../../../components";
import { Orders } from "@sno_oslo/browser-utils";
const { OrderManager } = Orders;

const Receipt = (props: any) => {
	const history = props.history;

	const closeHandler = () => {
		OrderManager.removeAllProducts();
		history.push({ pathname: "/user/my-products/", state: { direction: null } });
	};

	return (
		<Page className="page-receipt" popup>
			<PageHeader closeHandler={closeHandler} />
			<ScrollView>
				<Container>
					<div className="header">
						<h2>Hurra!</h2>
						<p>Passene dine er klare</p>
					</div>

					<div className="videocontainer">
						<video autoPlay playsInline muted controls={false} width="300px">
							<source
								src="https://videos.ctfassets.net/4gvqn75my5tn/4JGxzkXeSRMxuHSYX5QqRc/16fd6a72173e3549cf88394b329f674e/skipass3_no_spin.mp4"
								type="video/mp4"
							/>
						</video>
					</div>

					<div className="c-alertbox">
						<h3>Husk</h3>
						<p>Passet aktiveres når det brukes på SNØ første gang. Da blir det også personlig.</p>
						<Button onClick={closeHandler}>OK</Button>
					</div>
				</Container>
			</ScrollView>
		</Page>
	);
};
export default Receipt;
