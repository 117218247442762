import "./_page-redeem.scss";
import * as React from "react";
import { connect } from "react-redux";
import { Page, Container, ScrollView, ContainerHeader, ButtonGroup, Button } from "../../../components";
import { Fetch } from "../../../store/Fetch";
import { getUser } from "../../../store/actions/user";

import Alert from "../../../components/alert";

export function Redeem(props: any) {
	const redeemCode = "";
	const [inputValue, setInputValue] = React.useState(redeemCode);
	const [redeemProductInfo, setRedeemProductInfo] = React.useState(null);
	const [showAlert, setShowAlert] = React.useState(false);

	const setInputValueHandler = (event: any) => {
		setInputValue(event.target.value);
	};

	const onFocus = () => {
		setShowAlert(false);
	};

	const renderPriceClassFromRedeemedProduct = () => {
		switch (redeemProductInfo.priceClass) {
			case "adult":
				return <p>voksen</p>;
			case "youth":
				return <p>ung</p>;
			case "child":
				return <p>knøtt</p>;
			default:
				return null;
		}
	};

	async function redeemCodeHandler() {
		try {
			const redeemProductInfoResponse = await Fetch.get(
				"/user/{{env}}/access-products-check-code?code=" + inputValue,
			);
			setRedeemProductInfo(redeemProductInfoResponse);
		} catch (error) {
			setShowAlert(true);
		}
	}

	function getRedeemedProduct() {
		const data = {
			code: inputValue,
		};

		Fetch.post("/user/{{env}}/access-products", data);
		props.getUser();
		props.history.push("/user/my-products");
	}

	return (
		<Page
			className="page-redeem"
			popup
			closeHandler={() => {
				props.history.goBack();
			}}
		>
			<ScrollView>
				<Container>
					{redeemProductInfo ? (
						<div className="page-redeem--product-info">
							<img
								src={
									redeemProductInfo.type === "subscription"
										? "https://images.ctfassets.net/4gvqn75my5tn/59FQSlKbVKp255OIYoZgCq/0151294f5aa3d174da129825fba768ac/Medlemskap.png"
										: "https://images.ctfassets.net/4gvqn75my5tn/1R0fEUBt4jaWOmVaL41upP/5d6f47fe93103e08e9d3e4e4a1322d8b/Dagspass.png"
								}
								alt="Bildet av produkt"
							/>
							<h1>{redeemProductInfo.type === "subscription" ? "Medlemskap" : "Dagspass"}</h1>
							<h2>
								Gyldig for en{renderPriceClassFromRedeemedProduct()}i {redeemProductInfo.duration}
								{redeemProductInfo.type === "subscription"
									? " måneder"
									: redeemProductInfo.duration === 1
									? " dag"
									: " dager"}
							</h2>
							<p>Aktiveres når du scanner deg inn.</p>
							{redeemProductInfo.type === "subscription" ? (
								<p>Medlemskapet kan brukes av {redeemProductInfo.userNameForPass}</p>
							) : null}
							<ButtonGroup>
								<Button full onClick={getRedeemedProduct}>
									Hent passet
								</Button>
							</ButtonGroup>
						</div>
					) : (
						<div>
							<ContainerHeader left>
								<h2>Bruk hentekode</h2>
							</ContainerHeader>
							<form>
								<label className="c-label">Hentekode</label>
								<input
									onFocus={onFocus}
									value={inputValue}
									onChange={setInputValueHandler}
									className={showAlert ? "alert--error c-input" : "c-input"}
									placeholder="Skriv inn koden"
								/>
								{showAlert ? (
									<Alert className="alert--error-text" alertMessage="Koden finnes ikke"></Alert>
								) : null}
								<ButtonGroup>
									<Button full onClick={redeemCodeHandler}>
										Send inn kode
									</Button>
								</ButtonGroup>
							</form>
						</div>
					)}
				</Container>
			</ScrollView>
		</Page>
	);
}

export default connect(
	(state) => {
		return {};
	},
	(dispatch) => {
		return {
			getUser: () => dispatch(getUser()),
		};
	},
)(Redeem);
