import "./_page-myproducts.scss";
// tslint:disable-next-line: no-var-requires
const snowman = require("../../assets/svg/snowman.svg");

import * as React from "react";
import { connect } from "react-redux";
import { getAllAccessProducts } from "../../store/selectors/user";
import {
	Page,
	Container,
	ScrollView,
	ContainerHeader,
	ProductList,
	ButtonGroup,
	Button,
	ForwardLink,
} from "../../components";

export function MyProducts(props: any) {
	const { accessProducts } = props;
	const passes = accessProducts;

	React.useEffect(() => {
		const element = document.getElementById(props.location.state);
		if (element !== null) {
			setTimeout(() => element.scrollIntoView(), 500);
		}
	}, [props.location.state]);

	return (
		<Page className="page-myproducts">
			<ScrollView>
				<Container>
					<ContainerHeader>
						{passes && passes.length === 1 ? <h2>Mitt pass</h2> : <h2>Mine pass</h2>}
					</ContainerHeader>
					{passes.length < 1 ? (
						<div className="empty-state">
							<img src={snowman} style={{ height: "200px" }} alt="snowman" />
							<p>
								Du har ingen aktive pass.
								<br /> Vil du kjøpe dagspass eller medlemskap?
							</p>
							<ButtonGroup>
								<ForwardLink to="/products/buy/" wide>
									<Button wide>Kjøp et pass</Button>
								</ForwardLink>
								<ForwardLink to="/products/redeem/" wide>
									<Button wide grey>
										Bruk hentekode
									</Button>
								</ForwardLink>
							</ButtonGroup>
						</div>
					) : (
						<ProductList products={passes} />
					)}
				</Container>
			</ScrollView>
		</Page>
	);
}

export default connect((state) => {
	return {
		accessProducts: getAllAccessProducts(state),
	};
})(MyProducts);
