import * as React from "react";
import "../../_icon.scss";

const Shower = ({ style = {}, fill = "", width = "100%", className = "", viewBox = "0 0 24 24" }) => (
	<svg
		width={width}
		style={style}
		height={width}
		fill={fill}
		viewBox={viewBox}
		xmlns="http://www.w3.org/2000/svg"
		className={`c-icon ${className || ""}`}
		xmlnsXlink="http://www.w3.org/1999/xlink"
	>
		<g>
			<path
				fill={fill}
				d="M6.5,24c-0.107,0-0.209-0.033-0.296-0.097l-1.315-0.966C4.518,23.297,4.023,23.5,3.5,23.5s-1.018-0.203-1.389-0.563   l-1.314,0.966C0.709,23.967,0.607,24,0.5,24c-0.159,0-0.309-0.076-0.403-0.204c-0.079-0.107-0.111-0.239-0.091-0.371   s0.09-0.248,0.198-0.327l1.383-1.016C1.529,21.893,1.5,21.698,1.5,21.5c0-0.913,0.631-1.709,1.5-1.934V18   c0-0.276,0.224-0.5,0.5-0.5S4,17.724,4,18v1.566c0.869,0.225,1.5,1.021,1.5,1.934c0,0.198-0.029,0.393-0.086,0.581l1.383,1.016   c0.108,0.079,0.178,0.195,0.198,0.327s-0.012,0.264-0.092,0.372C6.809,23.924,6.658,24,6.5,24z M3.5,20.5c-0.551,0-1,0.448-1,1   s0.449,1,1,1s1-0.448,1-1S4.051,20.5,3.5,20.5z"
			/>
			<path
				fill={fill}
				d="M14.5,24c-0.107,0-0.209-0.034-0.296-0.097l-1.314-0.966c-0.372,0.361-0.867,0.563-1.39,0.563s-1.018-0.203-1.389-0.563   l-1.314,0.966C8.709,23.967,8.607,24,8.5,24c-0.159,0-0.309-0.076-0.403-0.204c-0.079-0.107-0.111-0.239-0.091-0.371   c0.02-0.132,0.09-0.248,0.198-0.327l1.383-1.016C9.529,21.893,9.5,21.698,9.5,21.5c0-0.913,0.631-1.709,1.5-1.934V18   c0-0.276,0.224-0.5,0.5-0.5S12,17.724,12,18v1.566c0.869,0.225,1.5,1.021,1.5,1.934c0,0.199-0.029,0.393-0.086,0.581l1.382,1.016   c0.108,0.079,0.178,0.196,0.198,0.328c0.02,0.132-0.012,0.264-0.092,0.371C14.809,23.924,14.658,24,14.5,24z M11.5,20.5   c-0.551,0-1,0.448-1,1s0.449,1,1,1s1-0.448,1-1S12.051,20.5,11.5,20.5z"
			/>
			<path
				fill={fill}
				d="M8.5,19.5C8.224,19.5,8,19.276,8,19V3.5C8,2.673,8.673,2,9.5,2h7C17.327,2,18,2.673,18,3.5V4h1V3.5   C19,2.121,17.878,1,16.5,1h-7C8.121,1,7,2.121,7,3.5V19c0,0.276-0.224,0.5-0.5,0.5S6,19.276,6,19V3.5C6,1.57,7.57,0,9.5,0h7   C18.43,0,20,1.57,20,3.5v0.533c2.261,0.251,4,2.169,4,4.467C24,8.776,23.776,9,23.5,9h-10C13.224,9,13,8.776,13,8.5   c0-2.298,1.739-4.216,4-4.467V3.5C17,3.224,16.776,3,16.5,3h-7C9.224,3,9,3.224,9,3.5V19C9,19.276,8.776,19.5,8.5,19.5z M22.964,8   C22.72,6.298,21.247,5,19.5,5h-2c-1.747,0-3.22,1.298-3.464,3H22.964z"
			/>
			<path
				fill={fill}
				d="M13.5,12c-0.276,0-0.5-0.224-0.5-0.5v-1c0-0.276,0.224-0.5,0.5-0.5s0.5,0.224,0.5,0.5v1C14,11.776,13.776,12,13.5,12z"
			/>
			<path
				fill={fill}
				d="M16.5,12c-0.276,0-0.5-0.224-0.5-0.5v-1c0-0.276,0.224-0.5,0.5-0.5s0.5,0.224,0.5,0.5v1C17,11.776,16.776,12,16.5,12z"
			/>
			<path
				fill={fill}
				d="M15.5,15c-0.276,0-0.5-0.224-0.5-0.5v-1c0-0.276,0.224-0.5,0.5-0.5s0.5,0.224,0.5,0.5v1C16,14.776,15.776,15,15.5,15z"
			/>
			<path
				fill={fill}
				d="M21.5,15c-0.276,0-0.5-0.224-0.5-0.5v-1c0-0.276,0.224-0.5,0.5-0.5s0.5,0.224,0.5,0.5v1C22,14.776,21.776,15,21.5,15z"
			/>
			<path
				fill={fill}
				d="M18.5,15c-0.276,0-0.5-0.224-0.5-0.5v-1c0-0.276,0.224-0.5,0.5-0.5s0.5,0.224,0.5,0.5v1C19,14.776,18.776,15,18.5,15z"
			/>
			<path
				fill={fill}
				d="M23.5,12c-0.276,0-0.5-0.224-0.5-0.5v-1c0-0.276,0.224-0.5,0.5-0.5s0.5,0.224,0.5,0.5v1C24,11.776,23.776,12,23.5,12z"
			/>
			<path
				fill={fill}
				d="M20.5,12c-0.276,0-0.5-0.224-0.5-0.5v-1c0-0.276,0.224-0.5,0.5-0.5s0.5,0.224,0.5,0.5v1C21,11.776,20.776,12,20.5,12z"
			/>
		</g>
	</svg>
);

export default Shower;
