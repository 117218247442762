import "./error-boundary.scss";

import * as React from "react";
// tslint:disable-next-line: no-var-requires
const snowman = require("../../assets/svg/snowman.svg");

// captures app wide uncatched error, lifecycle and render methods
// instead of show broken app, show this + logging + ability to reload app
export default class ErrorBoundary extends React.Component {
	state: any = {
		extended: false,
		error: null,
	};
	componentDidCatch(error: any, info: any) {
		this.setState({ error: { error, info } });
	}
	render() {
		const { error } = this.state;

		if (!error) {
			return this.props.children;
		}

		return (
			<div className="error-boundary" aria-live="assertive" style={{ textAlign: "center" }}>
				<img src={snowman} style={{ width: "100%" }} alt="snowman" />
				<h1 className="error-boundary__title">Noe gikk galt</h1>
				<p className="error-boundary__description">Vil du prøve å laste appen på nytt?</p>
				<button
					className="c-button"
					onClick={() => {
						window.location.reload();
					}}
				>
					Last inn på nytt
				</button>
			</div>
		);
	}
}
