import * as React from "react";
import "./_switch.scss";

export interface ISwitchProps {
	label?: string;
	value?: any;
	checked?: boolean;
}

class Switch extends React.Component<ISwitchProps> {
	render() {
		return (
			<div className="switch-wrapper">
				<label className="c-switch--label">{this.props.label}</label>
				<label className="c-switch">
					<input type="checkbox" value={this.props.value} checked={this.props.checked} />
					<span className="slider round" />
				</label>
			</div>
		);
	}
}

export default Switch;
