export function getPriceSummary(state: any) {
	return state.orders.priceSummary;
}

export function getOrderLines(state: any) {
	return state.orders.priceSummary.lines;
}

export function getSum(state: any) {
	return state.orders.priceSummary.sum;
}

export function getDiscountUsed(state: any) {
	return state.orders.priceSummary.discountUsed;
}
