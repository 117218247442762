import * as React from "react";
import "./_drawer.scss";
import { CSSTransition } from "react-transition-group";

const Drawer = (props: any) => {
	return (
		<CSSTransition in={props.expanded} classNames="c-drawer" timeout={100}>
			<div className="c-drawer">{props.children}</div>
		</CSSTransition>
	);
};

export default Drawer;
