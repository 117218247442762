export default class LocalStorage {
	public static get(key: string): any {
		try {
			return JSON.parse(localStorage.getItem(key));
		} catch (e) {
			return undefined;
		}
	}
	public static set(key: string, data: any): void {
		try {
			localStorage.setItem(key, JSON.stringify(data));
		} catch (e) {
			// do notihng
		}
	}
	public static remove(key: string): void {
		try {
			localStorage.removeItem(key);
		} catch (e) {
			// do notihng
		}
	}
}
