import "./_page-payment.scss";

import * as React from "react";
import { Page, Container, ScrollView, PageHeader, Button, ButtonGroup, Checkbox } from "../../../components";
import { CardNumberElement, CardExpiryElement, CardCVCElement, injectStripe } from "react-stripe-elements";
import { Orders } from "@sno_oslo/browser-utils";
import { logout } from "../../../store/actions/auth";
import { getUser } from "../../../store/actions/user";

import { connect } from "react-redux";
import { getNavigationVisible, getCurrentPageAllowsNavigation, getOnline } from "../../../store/selectors/app";

const { OrderManager } = Orders;

const Payment = (props: any) => {
	const history = props.history;
	const [errorMessage, setErrorMessage] = React.useState("");
	const [loading, setLoading] = React.useState(false);
	const [saveCard, setSaveCard] = React.useState(false);
	const [buttonDisabled, setButtonDisabled] = React.useState(false);

	const typeOfSubscription = props.match.params.type;
	const numberofdays = props.match.params.type;
	const backPath =
		props.match.params.type === "adult" || props.match.params.type === "youth"
			? `/products/buy/summary/${typeOfSubscription}`
			: `/products/buy/summary/${numberofdays}`;

	const backHandler = () => {
		history.push({ pathname: backPath, state: { direction: "left" } });
	};

	const closeHandler = () => {
		OrderManager.removeAllProducts();
		history.push({ pathname: "/products/buy", state: { direction: null } });
	};

	const submit = async () => {
		setButtonDisabled(true);

		props.stripe
			.createPaymentMethod("card")
			.then(({ paymentMethod, error }: any) => {
				if (error) {
					// tslint:disable-next-line: no-console
					console.log(error);
					throw error;
				}
				setLoading(true);
				// const config = { ...Config.ORDERMANAGER_OPTIONS, token: LocalStorage.get(Config.STORAGE_TOKEN) };

				OrderManager.submitOrder(null)
					.then((response) => {
						if (response) {
							setLoading(false);
							props.getUser();
							history.push({ pathname: "/products/buy/receipt", state: { direction: null } });
						} else {
							// tslint:disable-next-line: no-console
							console.error(response);
						}
					})
					.catch((submitError) => {
						setLoading(false);
						setButtonDisabled(false);
						const message =
							(submitError && submitError.message) || "Vi har litt problemer, prøv litt senere.";
						setErrorMessage(message);
						// tslint:disable-next-line: no-console
						console.error(submitError);
					});
			})
			.catch((createPMError: any) => {
				setLoading(false);
				setButtonDisabled(false);
				const message = (createPMError && createPMError.message) || "Vi har litt problemer, prøv litt senere.";
				setErrorMessage(message);
				// Stripe feil, kunne ikke generere token
				// Feilmeldniger finnes her: https://stripe.com/docs/api#errors
				// tslint:disable-next-line: no-console
				console.error(message);
			});
	};

	return (
		<Page className="page-payment" popup loading={loading} loadText="Gjennomfører kjøp...">
			<PageHeader closeHandler={closeHandler} backHandler={backHandler} backText="Oppsummering" />
			<ScrollView>
				<Container>
					<h2>Betal</h2>
					<div className="c-paymentform">
						<label className="c-label"></label>
						Kortnummer
						<CardNumberElement className="c-input" />
						<div className="c-paymentform--exp-cvc">
							<label className="c-label">
								Utløpsdato
								<CardExpiryElement className="c-input" />
							</label>
							<label className="c-label">
								CVC
								<CardCVCElement className="c-input" />
							</label>
						</div>
						<label>
							<Checkbox
								checked={saveCard}
								onClick={() => {
									setSaveCard(!saveCard);
								}}
							/>
							Lagre kort
						</label>
						{errorMessage ? <div className="c-errormessage">{errorMessage}</div> : null}
						<ButtonGroup align="bottom">
							<Button
								submit
								wide
								loading={buttonDisabled}
								disabled={buttonDisabled}
								onClick={() => {
									submit();
								}}
							>
								Betal
							</Button>
						</ButtonGroup>
					</div>
				</Container>
			</ScrollView>
		</Page>
	);
};
export default connect(
	(state) => {
		return {
			navigationVisible: getNavigationVisible(state),
			currentPageAllowsNavigation: getCurrentPageAllowsNavigation(state),
			online: getOnline(state),
		};
	},
	(dispatch) => {
		return {
			logout: () => dispatch(logout()),
			getUser: () => dispatch(getUser()),
		};
	},
	null,
	{ pure: false },
)(injectStripe(Payment));
