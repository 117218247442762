import "./container.scss";

import * as React from "react";
import css from "classnames";

export default function Container({
	children,
	blue,
	gray,
	nopadding,
	center,
	fillHeight,
}: {
	children: any;
	blue?: boolean;
	gray?: boolean;
	nopadding?: boolean;
	center?: any;
	fillHeight?: any;
}) {
	return (
		<div
			className={css("container", {
				"container--blue": blue,
				"container--gray": gray,
				"container--nopadding": nopadding,
				"container--center": center,
				"container--fill-height": fillHeight,
			})}
		>
			{children}
		</div>
	);
}
