import FontFaceObserver from "fontfaceobserver";

export default async function fontLoader() {
	try {
		await Promise.all([
			new FontFaceObserver("Nunito", {
				weight: 400,
				style: "normal",
			}).load(),
		]);
	} catch (e) {
		// tslint:disable-next-line: no-console
		console.log("fonts failed");
	}
}
