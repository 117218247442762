import "./app-wrapper.scss";

import * as React from "react";
import { Switch, Route } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { connect } from "react-redux";
import { getNavigationVisible, getCurrentPageAllowsNavigation, getOnline } from "../../store/selectors/app";
import { showNavigation, hideNavigation } from "../../store/actions/app";
import { OfflineBanner } from "..";
import Nav from "../navigation";

interface IAppWrapperProps {
	routes: any;
	navigationVisible?: boolean;
	online?: boolean;
	currentPageAllowsNavigation?: boolean;
	hideNavigation?: any;
	showNavigation?: any;
}

// this builds up the entire app layout, with nav in the bottom
export class AppWrapper extends React.Component<IAppWrapperProps> {
	componentDidMount() {
		const inputs = ["input", "textarea"];
		const targetDisallowedTypes = ["checkbox"];
		let tid: any;

		document.addEventListener(
			"focus",
			(e) => {
				const target = e.target as HTMLElement;
				if (
					inputs.includes(target.tagName.toLowerCase()) &&
					!targetDisallowedTypes.includes(target.getAttribute("type"))
				) {
					clearTimeout(tid);
					// always hide nav when input has focus (keyboard is visible)
					if (this.props.navigationVisible) {
						this.props.hideNavigation();
					}
				}
			},
			true,
		);

		document.addEventListener(
			"blur",
			(e) => {
				if (inputs.includes((e.target as HTMLElement).tagName.toLowerCase())) {
					// only show if current page actually does nav
					if (this.props.currentPageAllowsNavigation) {
						// do timeout to avoid flicker when jumping between inputs
						// this can be rewritten to use focusin and focusout and
						// e.relatedTarget === input ??
						tid = setTimeout(() => this.props.showNavigation(), 150);
					}
				}
			},
			true,
		);
	}
	render() {
		const { routes } = this.props;

		return (
			<div className="app-wrapper">
				<div className="app-wrapper__top">
					<Route
						render={({ location }) => {
							const direction = location.state ? location.state.direction : null;
							const doAnimation = !!direction;
							const duration = 500;

							return (
								<TransitionGroup component={React.Fragment}>
									<CSSTransition
										key={location.key}
										in={doAnimation}
										classNames={`animate-${direction || null}`}
										timeout={{ enter: duration, exit: duration }}
										enter={doAnimation}
										// Ask Eivind about this one...
										exit={direction === "bottom" ? true : false}
									>
										<Switch key={location.key} location={location}>
											{routes}
										</Switch>
									</CSSTransition>
								</TransitionGroup>
							);
						}}
					/>
				</div>
				<div className="app-wrapper__bottom">
					{!this.props.online ? <OfflineBanner /> : null}
					<CSSTransition
						component={React.Fragment}
						classNames={`animate-expander`}
						enter={true}
						exit={true}
						timeout={{ enter: 350, exit: 350 }}
					>
						<React.Fragment key="meny-fragment">
							{this.props.navigationVisible ? <Nav /> : null}
						</React.Fragment>
					</CSSTransition>
				</div>
			</div>
		);
	}
}

export default connect(
	(state) => {
		return {
			navigationVisible: getNavigationVisible(state),
			currentPageAllowsNavigation: getCurrentPageAllowsNavigation(state),
			online: getOnline(state),
		};
	},
	(dispatch) => {
		return {
			showNavigation: () => dispatch(showNavigation()),
			hideNavigation: () => dispatch(hideNavigation()),
		};
	},
	null,
	{
		pure: false, /// 😱 otherwise switch wont catch route changes
	},
)(AppWrapper);
