import "./_page-profile.scss";

import * as React from "react";
import { connect } from "react-redux";
import { getUser, getCouponsCount } from "../../store/selectors/user";
import { getNumberOfCards } from "../../store/selectors/cards";
import { Page, Container, ScrollView, ArrowLink, Only, ContainerHeader } from "../../components";
import LogoutButton from "./LogoutButton";
import { Icon, IconType } from "../../components/icon";

export function Profile({
	user,
	couponCodesCount,
	cardsCount,
}: {
	user: any;

	couponCodesCount: any;
	cardsCount: number;
}) {
	return (
		<Page className="page-profile">
			<ScrollView>
				<Container>
					<ContainerHeader>
						<h2>Profil</h2>
					</ContainerHeader>
				</Container>
				<Container gray>
					<div className="user-header">
						<Icon type={IconType.USER} />
						<h3>{user.name ? user.name : "Al Pinist"}</h3>
					</div>

					<ul className="c-user-list">
						<Only if={user.email}>
							<li>
								<h5>E-post</h5>
								<p>{user.email}</p>
							</li>
						</Only>

						<Only if={user.address}>
							<li>
								<h5>Adresse</h5>
								<p>
									{user.address}
									<br /> {user.postalCode} {user.city}{" "}
								</p>
							</li>
						</Only>
						<Only if={user.phone}>
							<li>
								<h5>Telefon</h5>
								<p>{user.phone}</p>
							</li>
						</Only>
						<Only if={user.birthDate}>
							<li>
								<h5>Fødselsdato</h5>
								<p>{user.birthDate}</p>
							</li>
						</Only>
					</ul>
				</Container>
				<Container>
					<ArrowLink
						to="/user/profile/cards"
						pretitle="Betalingsinformasjon"
						title={`${cardsCount || "Ingen"} kort lagret`}
					/>
					<ArrowLink
						to="/user/profile/coupons"
						pretitle="Avtalekoder"
						title={`${couponCodesCount || "Ingen"} ${couponCodesCount > 1 ? "aktive" : "aktiv"} ${
							couponCodesCount > 1 ? "avtalekoder" : "avtalekode"
						}`}
					/>
					{/* <ArrowLink to="/user/profile/orderhistory" pretitle="Ordrehistorikk" title="Se tidligere kjøp" /> */}
					<ArrowLink to="/user/profile/delete" pretitle="Slett meg" title="Slett profil" />
				</Container>
				<Container center>
					<LogoutButton gray />
				</Container>
			</ScrollView>
		</Page>
	);
}

export default connect((state) => {
	return {
		user: getUser(state),
		couponCodesCount: getCouponsCount(state),
		cardsCount: getNumberOfCards(state),
	};
})(Profile);
