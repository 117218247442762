import NativeBridge from "../bridge/NativeBridge";
import NativeEvent from "../bridge/NativeEvent";

export default function(store: any) {
	// listen for events from natve and trigger stuff
	// in the web app (and redux store)
	// const defaultCallback = (message: string) => {
	// 	window.console.log(message);
	// };

	NativeBridge.listen(NativeEvent.HELLO, (data?: any) => {
		alert("HELLO from web, data: " + JSON.stringify(data));
	});
	NativeBridge.listen(NativeEvent.KEYBOARD_WILL_SHOW, (data?: any) => {
		// tslint:disable-next-line: no-console
		console.log("keyboard will show");
	});
	NativeBridge.listen(NativeEvent.KEYBOARD_WILL_HIDE, (data?: any) => {
		// tslint:disable-next-line: no-console
		console.log("keyboard will hide");
	});
	NativeBridge.listen("native-event:keyboard-did-show", () => {
		window.scrollTo(0, 0);
	});
	NativeBridge.listen("native-event:keyboard-did-hide", () => {
		window.scrollTo(0, 0);
	});
	NativeBridge.listen("native-event:statusbar-tapped", () => {
		window.scrollTo(0, 0);
	});
}
