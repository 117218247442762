export const isDebug = () => {
	if (localStorage.getItem("debug")) {
		return true;
	} else {
		return false;
	}
};

export const setDebug = (status: boolean) => {
	if (status) {
		localStorage.setItem("debug", "true");
	} else {
		localStorage.removeItem("debug");
	}
};
