import Config from "../Config";

// this adds headers and auth to request before it is sent
export default function(store: any) {
	return (request: Request) => {
		const token = store.getState().auth.token;

		request.headers.set("x-api-key", Config.REST_API_KEY);

		if (token) {
			request.headers.set("Authorization", `Bearer ${token}`);
		}
	};
}
