import "./_page-subscriptionduration.scss";

import * as React from "react";
import {
	Page,
	Container,
	ContainerHeader,
	ScrollView,
	ForwardLink,
	PageHeader,
	Drawer,
	Only,
	Currency,
} from "../../../../components";
import { Icon, IconType } from "../../../../components/icon";
import { connect } from "react-redux";
import { getOnline } from "../../../../store/selectors/app";
import { getPriceSummary } from "../../../../store/selectors/orders";
import { logout } from "../../../../store/actions/auth";
import { calculatePriceSummary } from "../../../../store/actions/orders";
import OrderSummary from "../../../../components/ordersummary";
import { Types } from "@sno_oslo/shared-utils";
import { Orders } from "@sno_oslo/browser-utils";

const { OrderManager, SubscriptionType } = Orders;

const BuySubscriptionDuration = (props: any) => {
	const [activeType, setActiveType] = React.useState(0);
	const [products, setProducts] = React.useState(OrderManager.getAllProducts());
	const history = props.history;
	let typeOfSubscription: Types.PriceClass = props.match.params.type;

	switch (typeOfSubscription) {
		case "youth":
			typeOfSubscription = Types.PriceClass.YOUTH;
			break;
		case "adult":
			typeOfSubscription = Types.PriceClass.ADULT;
			break;
		default:
			typeOfSubscription = null;
	}

	const priceClassesOneYear = OrderManager.getPriceClassesForSubscriptionType(SubscriptionType.ONE_YEAR);
	const priceClassesSixMonths = OrderManager.getPriceClassesForSubscriptionType(SubscriptionType.SIX_MONTHS);
	let priceOneYear = null;
	let priceSixMonths = null;
	if (priceClassesOneYear[typeOfSubscription] !== undefined) {
		priceOneYear = priceClassesOneYear[typeOfSubscription].price;
	}
	if (priceClassesSixMonths[typeOfSubscription] !== undefined) {
		priceSixMonths = priceClassesSixMonths[typeOfSubscription].price;
	}

	React.useEffect(() => {
		const orderManagerChangeHandler = () => {
			let timeOut = null;
			if (timeOut) {
				clearTimeout(timeOut);
			}
			timeOut = setTimeout(() => {
				props.calculatePriceSummary();
			});
			setProducts(OrderManager.getAllProducts());
		};
		OrderManager.onChange(orderManagerChangeHandler);
	}, [products, props]);

	const backHandler = () => {
		OrderManager.removeAllProducts();
		props.history.push({ pathname: "/products/buy/subscription", state: { direction: "left" } });
	};

	const closeHandler = () => {
		OrderManager.removeAllProducts();
		props.history.push({ pathname: "/products/buy", state: { direction: null } });
	};

	const setActiveTypeHandler = (activeTypeVal: number) => {
		setActiveType(activeTypeVal);
	};

	return (
		<Page className="page-subscriptionduration" popup>
			<PageHeader closeHandler={closeHandler} backHandler={backHandler} backText="Medlemskap" />

			<ScrollView>
				<Container>
					<ContainerHeader left>
						<h2>Hvor lang fastperiode ønsker du?</h2>
						<p>Vi garanterer at prisen forblir den samme gjennom hele fastprisperioden. </p>
					</ContainerHeader>

					<ul className="c-select-list">
						{/* 	<Only if={priceOneYear}> */}
						<li
							className={activeType === 1 ? "c-button--active" : ""}
							onClick={() => {
								OrderManager.removeAllProducts();
								OrderManager.addSubscription(SubscriptionType.ONE_YEAR, typeOfSubscription);
								setActiveTypeHandler(1);
							}}
						>
							{activeType === 1 ? <Icon type={IconType.CHECKMARK_HEAVY} block /> : null}
							<h3>12 mnd</h3>
							<Currency value={priceOneYear} />
						</li>
						{/* </Only> */}
						{/* <Only if={priceSixMonths}> */}
						<li
							className={activeType === 2 ? "c-button--active" : ""}
							onClick={() => {
								OrderManager.removeAllProducts();
								OrderManager.addSubscription(SubscriptionType.SIX_MONTHS, typeOfSubscription);
								setActiveTypeHandler(2);
							}}
						>
							{activeType === 2 ? <Icon type={IconType.CHECKMARK_HEAVY} block /> : null}
							<h3>6 mnd</h3>
							<Currency value={priceSixMonths} />
						</li>
						{/* </Only> */}
					</ul>
				</Container>
			</ScrollView>
			<Drawer expanded={products.length >= 1}>
				<Only if={products.length >= 1}>
					<OrderSummary history={history} />
					<ForwardLink arrow button wide to={`/products/buy/addons/${typeOfSubscription}`}>
						Videre
					</ForwardLink>
				</Only>
			</Drawer>
		</Page>
	);
};
export default connect(
	(state) => {
		return {
			online: getOnline(state),
			priceSummary: getPriceSummary(state),
		};
	},
	(dispatch) => {
		return {
			logout: () => dispatch(logout()),
			calculatePriceSummary: () => dispatch(calculatePriceSummary()),
		};
	},
	null,
)(BuySubscriptionDuration);
