import "./_loginform.scss";
import * as React from "react";
import Config from "../../../store/Config";
import { Loader } from "../../../components";

export default function LoginForm(props: any) {
	const [loaded, setLoaded] = React.useState(false);
	return (
		<div className="c-loginform">
			{!loaded ? <Loader /> : null}
			<iframe
				onLoad={() => setLoaded(true)}
				title="Login"
				scrolling="no"
				src={`https://login${Config.ENV === "production" ? "" : "-dev"}.snooslo.no/${
					props.register ? "#/new-user/" : ""
				}?clientType=app`}
			/>
		</div>
	);
}
