import * as React from "react";
import "./_productlist.scss";
import { Button, ButtonGroup, PopupLink } from "..";
import { Utils } from "@sno_oslo/browser-utils";
import { Types } from "@sno_oslo/shared-utils";
import css from "classnames";

const QRCode = (props: any) => {
	const [qrCode, setQrCode] = React.useState(null);
	React.useEffect(() => {
		Utils.QRCode.getQrCode(props.qr, "svg", "#ffffffff", "#000000", 4, "H").then((res) => setQrCode(res));
	}, [props.qr]);
	return <div className="c-productlist--qrcode" dangerouslySetInnerHTML={{ __html: qrCode }} />;
};

const text: any = {
	productType: {
		daypass: "Dagspass",
		subscription: "Medlemskap",
	},
	addonType: {
		shower: "Garderobe med dusj",
	},
};

const ProductList = (props: { products: Array<Types.IAccessProduct> }) => {
	const products = props.products || [];
	const sortedProducts: Array<Types.IAccessProduct> = products.sort((a: any, b: any) => {
		const dateA: any = new Date(a.purchasedAt);
		const dateB: any = new Date(b.purchasedAt);
		return dateB - dateA;
	});

	return (
		<div className={css("c-productlist", { "c-productlist--one": products.length === 1 })}>
			<ul>
				{sortedProducts.map((product) => {
					return (
						<li key={product.id} className="c-productlist--product" id={product.id}>
							{product.type ? <h2>{text.productType[product.type]}</h2> : ""}
							<p>
								{product.type === "subscription"
									? product.userNameForPass
									: product.productName.replace(text.productType[product.type], "")}
							</p>
							<QRCode qr={product.qrCode} />
							<ButtonGroup align="bottom">
								<PopupLink open to={`/user/my-products/${product.id}/`}>
									<Button grey thin>
										Se detaljer
									</Button>
								</PopupLink>
							</ButtonGroup>
						</li>
					);
				})}
			</ul>
		</div>
	);
};

export default ProductList;
