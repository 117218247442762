import { Fetch } from "../../store/Fetch";
import LocalStorage from "../../store/LocalStorage";
import { getUser } from "../../store/actions/user";
import { getCards } from "../../store/actions/cards";
import { calculatePriceSummary } from "../../store/actions/orders";
import { logout } from "../../store/actions/auth";
import { setOnlineStatus } from "../../store/actions/app";
import fontLoader from "../font-loader";
import NativeEvent from "../bridge/NativeEvent";
import NativeBridge from "../bridge/NativeBridge";
import { Orders } from "@sno_oslo/browser-utils";
import Config from "../../store/Config";

const { OrderManager } = Orders;

// simple util to check if stored token is valid
async function isTokenValid() {
	try {
		await Fetch.get("/user/{{env}}/");

		return true;
	} catch {
		return false;
	}
}

export default async function(store: any) {
	const token = LocalStorage.get("token");
	const validToken = token && (await isTokenValid());

	if (validToken) {
		// all data required for basic app functions should be put here
		await Promise.all([store.dispatch(getCards()), store.dispatch(getUser())]);
	} else {
		if (token) {
			// invalid token, auto log out, and do no more data loading
			// since login action will load all necessary stuff
			store.dispatch(logout());
		}
	}

	await fontLoader();

	// connectivity listener
	window.addEventListener("online", () => store.dispatch(setOnlineStatus(true)));
	window.addEventListener("offline", () => store.dispatch(setOnlineStatus(false)));

	// app has loaded everything and is ready to be visible
	NativeBridge.broadcastNative(NativeEvent.APP_READY);

	OrderManager.init({ token, ...Config.ORDERMANAGER_OPTIONS })
		.then(() => {
			return Promise.all([store.dispatch(calculatePriceSummary())]);
		})
		.catch((error) => {
			// tslint:disable-next-line: no-console
			console.error(error);
		});
}
