import * as React from "react";
import { connect } from "react-redux";
import { getOnline } from "../../store/selectors/app";

export function OnlyOffline(props: any) {
	return !props.online ? <>{props.children}</> : null;
}

export default connect((state) => {
	return {
		online: getOnline(state),
	};
})(OnlyOffline);
