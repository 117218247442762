import * as React from "react";
import "./_textblock.scss";
import css from "classnames";

interface ITextBlockProps {
	className?: any;
	centered?: boolean;
}

const TextBlock: React.FunctionComponent<ITextBlockProps> = (props) => {
	const classNames = css("c-textblock", props.className, {
		"c-textblock--centered": props.centered,
	});

	return <div className={classNames}>{props.children}</div>;
};

export default TextBlock;
