import * as React from "react";
import { connect } from "react-redux";
import { login as performLogin } from "../../store/actions/auth";
import Login from "../../pages/login/Login";
import { getToken } from "../../store/selectors/auth";

export class AuthGate extends React.Component<any> {
	state = {
		loading: false,
	};

	constructor(props: any) {
		super(props);
		this.onMessage = this.onMessage.bind(this);
	}
	componentDidMount() {
		window.addEventListener("message", this.onMessage);
	}
	componentWillUnmount() {
		window.removeEventListener("message", this.onMessage);
	}
	async onMessage({ data, origin }: MessageEvent) {
		const allowedOrigins = ["https://login-dev.snooslo.no", "https://login.snooslo.no"];

		if (data && data.token && allowedOrigins.includes(origin)) {
			// this loading thing should probably be a redux prop
			this.setState({ loading: true });

			await performLogin(data.token);

			this.setState({ loading: false });
		}
	}
	render() {
		const { token, children } = this.props;
		const { loading } = this.state;

		if (!token || loading) {
			return <Login />;
		} else {
			return <>{children}</>;
		}
	}
}

export default connect(
	(state) => {
		return {
			token: getToken(state),
		};
	},
	(dispatch) => {
		return {
			login: (token: string) => dispatch(performLogin(token)),
		};
	},
)(AuthGate);
