import * as React from "react";
import "../../_icon.scss";

const User = ({ style = {}, fill = "", width = "100%", className = "", viewBox = "0 0 24 24" }) => (
	<svg
		width={width}
		style={style}
		height={width}
		fill={fill}
		viewBox={viewBox}
		xmlns="http://www.w3.org/2000/svg"
		className={`c-icon ${className || ""}`}
		xmlnsXlink="http://www.w3.org/1999/xlink"
	>
		<g>
			<path
				fill={fill}
				d="M0.5,23.5c-0.135,0-0.266-0.055-0.359-0.152C0.046,23.25-0.005,23.118,0,22.983c0.051-1.485,0.404-2.918,1.049-4.257   c0.738-1.475,3.242-2.396,6.709-3.67l0.915-0.337C8.801,14.671,8.923,14.454,9,14.139c0.207-0.851,0.012-1.927-0.13-2.083   C7.832,10.915,6.82,9.509,6.82,6c0-3.799,2.602-5.5,5.18-5.5s5.18,1.701,5.18,5.5c0,3.507-1.013,4.914-2.05,6.055   c-0.119,0.144-0.342,1.217-0.129,2.088c0.077,0.313,0.198,0.528,0.325,0.575l0.903,0.333c3.473,1.276,5.983,2.198,6.719,3.669   c0.648,1.344,1.002,2.778,1.052,4.263c0.005,0.134-0.046,0.267-0.14,0.365c-0.094,0.097-0.225,0.153-0.36,0.153H0.5z M22.969,22.5   c-0.101-1.161-0.41-2.283-0.919-3.34c-0.555-1.11-3.155-2.065-6.165-3.171l-0.905-0.333c-0.32-0.118-0.745-0.435-0.95-1.276   c-0.232-0.948-0.125-2.464,0.361-2.998C15.296,10.386,16.18,9.15,16.18,6c0-2.24-1.292-4.5-4.18-4.5C9.112,1.5,7.82,3.76,7.82,6   c0,3.152,0.884,4.387,1.79,5.383c0.484,0.532,0.592,2.046,0.362,2.992c-0.205,0.843-0.63,1.162-0.951,1.281l-0.917,0.338   c-3.005,1.104-5.599,2.059-6.156,3.173C1.44,20.218,1.132,21.339,1.031,22.5H22.969z"
			/>
		</g>
	</svg>
);

export default User;
