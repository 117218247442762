import "./_page-quantity.scss";

import * as React from "react";
import {
	Page,
	Container,
	ScrollView,
	PageHeader,
	ForwardLink,
	Only,
	ContainerHeader,
	Drawer,
	Currency,
} from "../../../../components";

import { Orders } from "@sno_oslo/browser-utils";
import { Types } from "@sno_oslo/shared-utils";
import OrderSummary from "../../../../components/ordersummary";
import css from "classnames";
import { connect } from "react-redux";
import { getOnline } from "../../../../store/selectors/app";
import { getPriceSummary } from "../../../../store/selectors/orders";
import { logout } from "../../../../store/actions/auth";
import { calculatePriceSummary } from "../../../../store/actions/orders";

const { OrderManager, DayPassType } = Orders;
const { ProductType } = Types;

const BuyDaypassQuantity = (props: any) => {
	const numberofdays = props.match.params.type || 1;
	const history = props.history;
	const [products, setProducts] = React.useState(OrderManager.getAllProducts());
	let dayPassType = DayPassType.ONE_DAY;

	switch (numberofdays) {
		case "1":
			dayPassType = DayPassType.ONE_DAY;
			break;
		case "2":
			dayPassType = DayPassType.TWO_DAYS;
			break;
		case "3":
			dayPassType = DayPassType.THREE_DAYS;
			break;
		case "7":
			dayPassType = DayPassType.SEVEN_DAYS;
			break;
		case "30":
			dayPassType = DayPassType.THIRTY_DAYS;
			break;
		default:
			dayPassType = 0;
	}

	const priceclasses = OrderManager.getPriceClassesForDayPassType(dayPassType);

	const orderManagerChangeHandler = () => {
		let timeOut = null;
		if (timeOut) {
			clearTimeout(timeOut);
		}
		timeOut = setTimeout(() => {
			props.calculatePriceSummary();
		});
		setProducts(OrderManager.getAllProducts());
	};

	React.useEffect(() => {
		OrderManager.onChange(orderManagerChangeHandler);
		// eslint-disable-next-line
	}, [products]);

	const backHandler = () => {
		OrderManager.removeAllProducts();
		history.push({ pathname: "/products/buy/daypass", state: { direction: "left" } });
	};

	const closeHandler = () => {
		OrderManager.removeAllProducts();
		history.push({ pathname: "/products/buy", state: { direction: null } });
	};

	const QuantityPicker = (pickerProps: any) => {
		const [quantity, setQuantity] = React.useState(
			OrderManager.getNumberOfDayPassProductsOfType(pickerProps.dayPassType, pickerProps.priceClass),
		);

		return (
			<div className={css("c-quantitypicker", { "c-quantitypicker--selected": quantity >= 1 })}>
				<button
					aria-label="Fjern"
					onClick={() => {
						if (quantity >= 1) {
							OrderManager.removeOneProductByType(props.productType, props.dayPassType, props.priceClass);
							setQuantity(
								OrderManager.getNumberOfDayPassProductsOfType(props.dayPassType, props.priceClass),
							);
						}
					}}
				>
					-
				</button>
				<span aria-label="Antall:">{quantity}</span>
				<button
					aria-label="Legg til"
					onClick={() => {
						OrderManager.addDayPass(props.dayPassType, props.priceClass);
						setQuantity(OrderManager.getNumberOfDayPassProductsOfType(props.dayPassType, props.priceClass));
					}}
				>
					+
				</button>
			</div>
		);
	};

	return (
		<Page className="page-quantity" popup>
			<PageHeader closeHandler={closeHandler} backHandler={backHandler} backText="Antall dager" />
			<ScrollView>
				<Container>
					<ContainerHeader left>
						<h2>Velg antall {numberofdays === 1 ? "dagspass" : numberofdays + "-dagerspass"}</h2>
					</ContainerHeader>

					<div className="c-quantity-selector">
						<Only if={priceclasses.adult}>
							<div className="c-quantity-selector--row">
								<div className="c-quantity-selector--priceclass">
									<h3>Voksen</h3>
									<p>Over 15 år</p>
								</div>
								<div className="c-quantity-selector--price">
									<Currency value={priceclasses.adult ? priceclasses.adult.price : null} />
								</div>
								<div className="c-quantity-selector--quantity">
									<QuantityPicker
										dayPassType={dayPassType}
										priceClass={Types.PriceClass.ADULT}
										productType={ProductType.DAYPASS}
									/>
								</div>
							</div>
						</Only>
						<Only if={priceclasses.youth}>
							<div className="c-quantity-selector--row">
								<div className="c-quantity-selector--priceclass">
									<h3>Ung</h3>
									<p>6 - 15 år</p>
								</div>
								<div className="c-quantity-selector--price">
									<Currency value={priceclasses.child ? priceclasses.youth.standardPrice : null} />
								</div>
								<div className="c-quantity-selector--quantity">
									<QuantityPicker
										dayPassType={dayPassType}
										priceClass={Types.PriceClass.YOUTH}
										productType={ProductType.DAYPASS}
									/>
								</div>
							</div>
						</Only>
						<Only if={priceclasses.child}>
							<div className="c-quantity-selector--row">
								<div className="c-quantity-selector--priceclass">
									<h3>Knøtt</h3>
									<p>Under 6 år</p>
								</div>
								<div className="c-quantity-selector--price">
									<Currency value={priceclasses.child ? priceclasses.child.standardPrice : null} />
								</div>
								<div className="c-quantity-selector--quantity">
									<QuantityPicker
										dayPassType={dayPassType}
										priceClass={Types.PriceClass.CHILD}
										productType={ProductType.DAYPASS}
									/>
								</div>
							</div>
						</Only>
					</div>
				</Container>
			</ScrollView>
			<Drawer expanded={products.length > 0}>
				<Only if={products.length > 0}>
					<OrderSummary history={history} />
					<ForwardLink arrow button wide to={`/products/buy/addons/${numberofdays}`}>
						Videre
					</ForwardLink>
				</Only>
			</Drawer>
		</Page>
	);
};
export default connect(
	(state) => {
		return {
			online: getOnline(state),
			priceSummary: getPriceSummary(state),
		};
	},
	(dispatch) => {
		return {
			logout: () => dispatch(logout()),
			calculatePriceSummary: () => dispatch(calculatePriceSummary()),
		};
	},
	null,
)(BuyDaypassQuantity);
