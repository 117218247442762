export const OrderAction = {
	SUCCESS: "order:success",
	LOADING: "order:loading",
	LOADED: "order:loaded",
	ERROR: "order:error",
};

export function success(response: any) {
	return {
		type: OrderAction.SUCCESS,
		payload: response,
	};
}

export function loaded() {
	return {
		type: OrderAction.LOADED,
	};
}

export function loading() {
	return {
		type: OrderAction.LOADING,
	};
}

export function error(err: any) {
	return {
		type: OrderAction.ERROR,
		payload: err,
	};
}
