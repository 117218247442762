import * as appActions from "./creators/app";

export function showNavigation(): any {
	return (dispatcher: any) => {
		dispatcher(appActions.showNavigation());
	};
}

export function hideNavigation(): any {
	return (dispatcher: any) => {
		dispatcher(appActions.hideNavigation());
	};
}

export function setCurrentPageAllowsNavigation(state: boolean): any {
	return (dispatcher: any) => {
		dispatcher(appActions.setCurrentPageAllowsNavigation(state));
	};
}

export function setOnlineStatus(status: boolean): any {
	return (dispatcher: any) => {
		dispatcher(appActions.setOnlineStatus(status));
	};
}
