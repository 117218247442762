import "./touchable.scss";

import * as React from "react";
import css from "classnames";
import { isPageAnimating } from "../../helpers/utils";

// wrap any element that can be interacted with (clicked) with this component for
// auto touch feedback
export default class Touchable extends React.PureComponent<any> {
	state = {
		touching: false,
	};
	render() {
		const { children: child, disabled }: any = this.props;

		return React.cloneElement(child, {
			...child.props,
			onTouchStart: () => this.setState({ touching: true && !disabled && !isPageAnimating() }),
			onTouchEnd: () => this.setState({ touching: false }),
			onTouchCancel: () => this.setState({ touching: false }),
			className: css(child.props.className, "touchable", { "touchable--touching": this.state.touching }),
		});
	}
}
