import * as orderActions from "./creators/orders";
import LocalStorage from "../LocalStorage";

import Config from "../Config";
import { Orders } from "@sno_oslo/browser-utils";
const { OrderManager } = Orders;

export function calculatePriceSummary(): any {
	return async (dispatch: (type: any) => any) => {
		try {
			const config = { ...Config.ORDERMANAGER_OPTIONS, token: LocalStorage.get(Config.STORAGE_TOKEN) };
			const priceSummary = await OrderManager.getPriceSummary(config);

			dispatch(orderActions.success(priceSummary));
		} catch (e) {
			dispatch(orderActions.error(e));
		} finally {
			dispatch(orderActions.loaded());
		}
	};
}
