import "./_page-daypass.scss";

import * as React from "react";
import { Page, Container, ContainerHeader, ScrollView, ForwardLink, PageHeader, Currency } from "../../../components";
import { Orders } from "@sno_oslo/browser-utils";
const { OrderManager, DayPassType } = Orders;

const BuyDaypass = (props: any) => {
	const closeHandler = () => {
		props.history.push({ pathname: "/products/buy", state: { direction: null } });
	};

	return (
		<Page className="page-daypass" popup>
			<PageHeader closeHandler={closeHandler} />
			<ScrollView>
				<Container>
					<ContainerHeader left>
						<h2>Velg antall dager</h2>
					</ContainerHeader>

					<ul className="c-select-list">
						<li>
							<ForwardLink to="/products/buy/daypass/quantity/1">
								<h3>1 dag</h3>
								<p>
									<Currency
										value={
											OrderManager.getPriceClassesForDayPassType(DayPassType.ONE_DAY).adult.price
										}
									/>
								</p>
							</ForwardLink>
						</li>
						<li>
							<ForwardLink to="/products/buy/daypass/quantity/2">
								<h3>2 dager</h3>
								<p>
									<Currency
										value={
											OrderManager.getPriceClassesForDayPassType(DayPassType.TWO_DAYS).adult.price
										}
									/>
								</p>
							</ForwardLink>
						</li>
						<li>
							<ForwardLink to="/products/buy/daypass/quantity/3">
								<h3>3 dager</h3>
								<p>
									<Currency
										value={
											OrderManager.getPriceClassesForDayPassType(DayPassType.THREE_DAYS).adult
												.price
										}
									/>
								</p>
							</ForwardLink>
						</li>
						<li>
							<ForwardLink to="/products/buy/daypass/quantity/7">
								<h3>7 dager</h3>
								<p>
									<Currency
										value={
											OrderManager.getPriceClassesForDayPassType(DayPassType.SEVEN_DAYS).adult
												.price
										}
									/>
								</p>
							</ForwardLink>
						</li>
						<li>
							<ForwardLink to="/products/buy/daypass/quantity/30">
								<h3>30 dager</h3>
								<p>
									<Currency
										value={
											OrderManager.getPriceClassesForDayPassType(DayPassType.THIRTY_DAYS).adult
												.price
										}
									/>
								</p>
							</ForwardLink>
						</li>
					</ul>
				</Container>
			</ScrollView>
		</Page>
	);
};
export default BuyDaypass;
