import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import app from "./reducers/app";
import auth from "./reducers/auth";
import user from "./reducers/user";
import orders from "./reducers/orders";
import cards from "./reducers/cards";

export default function(history: any) {
	return combineReducers({
		router: connectRouter(history),
		app,
		auth,
		user,
		orders,
		cards,
	});
}
