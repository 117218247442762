import { UserAction } from "../actions/creators/user";
import LocalStorage from "../../store/LocalStorage";
import { AuthAction } from "../../store/actions/creators/auth";
import Config from "../../store/Config";

interface IUserState {
	data: any;
	loading: boolean;
	error: any;
}

const init: IUserState = {
	data: LocalStorage.get(Config.STORAGE_USER),
	loading: false,
	error: null,
};

export default function(state: IUserState = init, { type, payload }: { type: string; payload: any }): IUserState {
	switch (type) {
		case AuthAction.LOGOUT:
			return {
				...state,
				data: null,
			};
		case UserAction.SUCCESS:
			return {
				...state,
				data: payload,
				error: null,
			};
		case UserAction.ERROR:
			return {
				...state,
				error: payload,
			};
		case UserAction.LOADED:
			return {
				...state,
				loading: false,
			};
		case UserAction.LOADING:
			return {
				...state,
				loading: true,
			};
		default:
			return state;
	}
}
