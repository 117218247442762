import { CardsAction } from "../actions/creators/cards";

interface ICardsState {
	cards: any;
	loading: boolean;
	error: any;
}

const init: ICardsState = {
	cards: [],
	loading: false,
	error: null,
};

export default function(state: ICardsState = init, { type, payload }: { type: string; payload: any }): ICardsState {
	switch (type) {
		case CardsAction.SUCCESS:
			return {
				...state,
				cards: payload,
				error: null,
			};
		case CardsAction.ERROR:
			return {
				...state,
				error: payload,
			};
		case CardsAction.LOADED:
			return {
				...state,
				loading: false,
			};
		case CardsAction.LOADING:
			return {
				...state,
				loading: true,
			};
		default:
			return state;
	}
}
