import "./_header.scss";

import * as React from "react";
import css from "classnames";

const Header = (props: any) => {
	const { children, blue } = props;

	return <div className={css("c-header", { "header--blue": blue })}>{children}</div>;
};
export default Header;
