import * as React from "react";
// tslint:disable-next-line: no-var-requires
const family = require("../../assets/svg/family.svg");

import { Page, ScrollView, Container, Button, TextBlock } from "../../components";
import LoginForm from "./LoginForm/LoginForm";

export default function Login(props: any) {
	const [introDone, setIntroDone] = React.useState(false);
	const [register, setRegister] = React.useState(false);

	if (!introDone) {
		return (
			<Page standalone allowsNavigation={false}>
				<ScrollView>
					<Container>
						<TextBlock centered>
							<h1>Velkommen</h1>
							<img src={family} style={{ width: "180px", height: "200px" }} alt="family" />
							<p>I januar 2020 åpner verdens råeste helårsarena for SNØ - i Lørenskog</p>
							<p>Denne appen er først og fremst din nøkkel inn i snøen</p>
						</TextBlock>

						<Button wide onClick={() => setIntroDone(true)}>
							Logg inn
						</Button>
						<Button
							wide
							outlined
							onClick={() => {
								setIntroDone(true);
								setRegister(true);
							}}
						>
							Registrer deg
						</Button>
					</Container>
				</ScrollView>
			</Page>
		);
	}

	return (
		<Container>
			<LoginForm register={register} />
		</Container>
	);
}
