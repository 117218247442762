import * as React from "react";
import { connect } from "react-redux";
import { Page, Container, ScrollView, ContainerHeader, Button, BackLink, PopupLink } from "../../../components";
import { getCards, getNumberOfCards } from "../../../store/selectors/cards";

import { Icon } from "../../../components/icon";

import "./_page-cards.scss";

export function Cards({ cards, numberOfCards }: { cards: any; numberOfCards: number }) {
	return (
		<Page allowsNavigation={false} className="page-cards">
			<ScrollView>
				<Container>
					<BackLink to="/user/profile" backText="Profil" />
					<ContainerHeader left>
						<h2>Betalingsinformasjon</h2>
					</ContainerHeader>
					{numberOfCards > 0 ? (
						<>
							<h3>{numberOfCards > 1 ? "Lagrede betalingskort" : "Lagret betalingskort"}</h3>
							{cards.cards.map((card: any) => {
								return (
									<div key={card.id} className="c-card">
										<div>
											<Icon
												type={
													card.brand === "visa"
														? "visa"
														: card.brand === "mastercard"
														? "mastercard"
														: "credit-card"
												}
											></Icon>
											<p>**** **** **** **** {card.last4}</p>
										</div>
										<p>
											Gyldig til {card.expMonth}/{card.expYear}
										</p>
									</div>
								);
							})}
						</>
					) : (
						<p>Du har ingen lagrede kort, vil du legge til et?</p>
					)}

					<PopupLink className="c-button--wide" open to="/user/profile/addcard">
						<Button wide secondary grey>
							Legg til nytt kort
						</Button>
					</PopupLink>
				</Container>
			</ScrollView>
		</Page>
	);
}

export default connect((state) => {
	return {
		cards: getCards(state),
		numberOfCards: getNumberOfCards(state),
	};
})(Cards);
