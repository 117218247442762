import { applyMiddleware, compose, createStore } from "redux";
import thunkMiddleware from "redux-thunk";
import { routerMiddleware } from "connected-react-router";
import { createLogger } from "redux-logger";
import reduxPromise from "redux-promise";
import makeRootReducer from "./make-root-reducer";

export default function(history: any) {
	const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

	return createStore(
		makeRootReducer(history),
		composeEnhancers(
			applyMiddleware(
				thunkMiddleware,
				reduxPromise,
				createLogger({ collapsed: true }),
				routerMiddleware(history),
			),
		),
	);
}
