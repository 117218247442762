import * as React from "react";
import { connect } from "react-redux";
import { getCoupons, getCouponsCount } from "../../../store/selectors/user";
import { Page, Container, ScrollView, ContainerHeader, Button, BackLink, PopupLink } from "../../../components";

import "./_page-coupons.scss";

export function Coupons({
	coupons,
	couponCodesCount,
	history,
}: {
	coupons: any;
	couponCodesCount: number;
	history: any;
}) {
	return (
		<Page className="page-coupons" allowsNavigation={false}>
			<ScrollView>
				<Container>
					<BackLink to="/user/profile" backText="Profil" />
					<ContainerHeader left>
						<h2>Avtalekoder</h2>
					</ContainerHeader>
					{couponCodesCount > 0 ? (
						<ul>
							{coupons.map((coupon: any) => {
								return (
									<div key={coupon.id} className="page-coupons--coupons">
										<li>
											<h4>{coupon.name}</h4>
											<h3>{coupon.id}</h3>
										</li>
									</div>
								);
							})}
						</ul>
					) : (
						<h4>Du har ingen avtalekoder, vil du legge til en?</h4>
					)}
					<PopupLink className="c-button--wide" open to={"/user/profile/couponcode"}>
						<Button wide grey>
							Legg til avtalekode
						</Button>
					</PopupLink>
				</Container>
			</ScrollView>
		</Page>
	);
}

export default connect((state) => {
	return {
		coupons: getCoupons(state),
		couponCodesCount: getCouponsCount(state),
	};
})(Coupons);
