export const AppAction = {
	SHOW_NAVIGATION: "app:show-navigation",
	HIDE_NAVIGATION: "app:hide-navigation",
	SET_CURRENT_PAGE_ALLOWS_NAVIGATION: "app:set-current-page-allows-navigation",
	SET_ONLINE_STATUS: "app:set-online-status",
};

export function setCurrentPageAllowsNavigation(state: boolean) {
	return {
		type: AppAction.SET_CURRENT_PAGE_ALLOWS_NAVIGATION,
		payload: state,
	};
}

export function setOnlineStatus(state: boolean) {
	return {
		type: AppAction.SET_ONLINE_STATUS,
		payload: state,
	};
}

export function showNavigation() {
	return {
		type: AppAction.SHOW_NAVIGATION,
	};
}

export function hideNavigation() {
	return {
		type: AppAction.HIDE_NAVIGATION,
	};
}
