import "./radio-list.scss";

import * as React from "react";
import { Route } from "react-router";
import { Icon, IconType } from "../icon";
import { Touchable } from "..";

export default function RadioList({
	onChange = () => {
		// ..
	},
	onClick = () => {
		// ..
	},
	children,
}: any) {
	return (
		<Route
			render={({ history }) => {
				return (
					<ul
						className="radio-list"
						onClick={onClick()}
						onChange={({ target }: { target: any }) => {
							onChange(target.value, history);
						}}
					>
						{children.map((i: any, index: any) => {
							return (
								<Touchable key={index}>
									<li>{i}</li>
								</Touchable>
							);
						})}
					</ul>
				);
			}}
		/>
	);
}

export function RadioListItem(props: any) {
	return (
		<label>
			<input type="radio" name="type" value={props.value} defaultChecked={props.checked} />
			<span>
				{props.children}
				<Icon type={IconType.ARROW_RIGHT} />
			</span>
		</label>
	);
}
