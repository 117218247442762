import "./collapsable-block.scss";
import * as React from "react";

const animationDuration = 400;

export default class CollapsableBlock extends React.PureComponent<any, any, any> {
	state = {
		height: this.props.open ? "auto" : 0,
	};
	ref: any = null;

	componentWillReceiveProps(nextProps: any) {
		if (this.props.open && !nextProps.open) {
			this.close();
		}

		if (!this.props.open && nextProps.open) {
			this.open();
		}
	}

	open() {
		if (this.state.height > 0) {
			return;
		}

		this.setState({ height: 0 });
		setTimeout(() => this.setState({ height: this.ref.offsetHeight }), 0);
		setTimeout(() => this.setState({ height: "auto" }), animationDuration);
	}

	close() {
		if (this.state.height === 0) {
			return;
		}

		this.setState({ height: this.ref.offsetHeight });
		setTimeout(() => this.setState({ height: 0 }), 0);
	}

	render() {
		const { children } = this.props;
		const { height } = this.state;

		return (
			<div className="collapsable-block" style={{ height }}>
				<div ref={(ref) => (this.ref = ref)}>{children}</div>
			</div>
		);
	}
}
