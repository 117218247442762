import "./_page-subscription.scss";

import * as React from "react";
import { Page, Container, ContainerHeader, ScrollView, ForwardLink, PageHeader } from "../../../components";
import { Icon, IconType } from "../../../components/icon";

const SubscriptionGroup = (props: any) => {
	return (
		<div className="c-subscription-group">
			<div className="c-subscription-group--container">
				<Icon type={props.firstIcon} block />
				<Icon type={props.secondIcon} block />
			</div>
			<div className="c-subscription-group--description">
				<p>{props.age}</p>
				<p>{props.ageRange}</p>
			</div>
			<Icon type={IconType.CHEVRON_RIGHT} block />
		</div>
	);
};

const BuySubscription = (props: any) => {
	const closeHandler = () => {
		props.history.push({ pathname: "/products/buy" });
	};

	return (
		<Page className="page-subscription" popup>
			<PageHeader closeHandler={closeHandler} />
			<ScrollView>
				<Container>
					<ContainerHeader left>
						<h2>Hvem er medlemskapet til?</h2>
					</ContainerHeader>
					<ForwardLink to="/products/buy/subscription/type/adult">
						<SubscriptionGroup
							firstIcon={IconType.MAN_HEAD}
							secondIcon={IconType.WOMAN_HEAD}
							age="Voksen"
							ageRange="Over 16 år"
						/>
					</ForwardLink>
					<ForwardLink to="/products/buy/subscription/type/youth">
						<SubscriptionGroup
							firstIcon={IconType.GIRL_HEAD}
							secondIcon={IconType.BOY_HEAD}
							age="Ung"
							ageRange="6-15 år"
						/>
					</ForwardLink>
				</Container>
			</ScrollView>
		</Page>
	);
};
export default BuySubscription;
