import * as React from "react";
import "../../_icon.scss";

const Pass = ({ style = {}, fill = "", width = "100%", className = "", viewBox = "0 0 24 24" }) => (
	<svg
		width={width}
		style={style}
		height={width}
		fill={fill}
		viewBox={viewBox}
		xmlns="http://www.w3.org/2000/svg"
		className={`c-icon ${className || ""}`}
		xmlnsXlink="http://www.w3.org/1999/xlink"
	>
		<g xmlns="http://www.w3.org/2000/svg">
			<path
				fill={fill}
				d="M2.504,20.5c-1.379,0-2.5-1.122-2.5-2.5V6c0-1.379,1.121-2.5,2.5-2.5h19c1.378,0,2.5,1.121,2.5,2.5v12   c0,1.378-1.122,2.5-2.5,2.5H2.504z M1.004,18c0,0.827,0.673,1.5,1.5,1.5h19c0.827,0,1.5-0.673,1.5-1.5V8.5h-22V18z M23.004,7.5V6   c0-0.827-0.673-1.5-1.5-1.5h-19c-0.827,0-1.5,0.673-1.5,1.5v1.5H23.004z"
			/>
			<path
				fill={fill}
				d="M17.504,12.5c-0.276,0-0.5-0.224-0.5-0.5c0-0.276,0.224-0.5,0.5-0.5h3c0.276,0,0.5,0.224,0.5,0.5   c0,0.276-0.224,0.5-0.5,0.5H17.504z"
			/>
			<path
				fill={fill}
				d="M3.504,12.5c-0.276,0-0.5-0.224-0.5-0.5c0-0.276,0.224-0.5,0.5-0.5h8c0.276,0,0.5,0.224,0.5,0.5c0,0.276-0.224,0.5-0.5,0.5   H3.504z"
			/>
			<path
				fill={fill}
				d="M3.504,15.5c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h3c0.276,0,0.5,0.224,0.5,0.5s-0.224,0.5-0.5,0.5H3.504z"
			/>
		</g>
	</svg>
);

export default Pass;
