import { OrderAction } from "../actions/creators/orders";
interface IOrderState {
	priceSummary: any;
	loading: boolean;
	error: any;
}

const init: IOrderState = {
	priceSummary: { lines: [], sum: 0, discountUsed: null },
	loading: false,
	error: null,
};

export default function(state: IOrderState = init, { type, payload }: { type: string; payload: any }): IOrderState {
	switch (type) {
		case OrderAction.SUCCESS:
			return {
				...state,
				priceSummary: payload,
				error: null,
			};
		case OrderAction.ERROR:
			return {
				...state,
				error: payload,
			};
		case OrderAction.LOADED:
			return {
				...state,
				loading: false,
			};
		case OrderAction.LOADING:
			return {
				...state,
				loading: true,
			};
		default:
			return state;
	}
}
