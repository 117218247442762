import "./_container-header.scss";
import * as React from "react";
import css from "classnames";

const ContainerHeader = (props: any) => {
	return (
		<header
			className={css("c-container-header", {
				"c-container-header--left": props.left,
			})}
		>
			{props.children}
		</header>
	);
};

export default ContainerHeader;
