import * as React from "react";

import { logout } from "../../store/actions/auth";
import { connect } from "react-redux";
import Button from "../../components/button";

export function LogoutButton(props) {
	return (
		<Button onClick={props.logout} grey>
			Logg ut
		</Button>
	);
}

export default connect(
	null,
	(dispatch) => {
		return {
			logout: () => dispatch(logout()),
		};
	},
)(LogoutButton);
