import * as React from "react";

// import all icons here
import Shower from "./icons/shower/shower";
import Baby from "./icons/baby/baby";
import User from "./icons/user/user";
import Snowflake from "./icons/snowflake/snowflake";
import Pass from "./icons/pass/pass";

const Icon = (props: any) => {
	switch (props.name) {
		case "shower":
			return <Shower {...props} />;
		case "baby":
			return <Baby {...props} />;
		case "user":
			return <User {...props} />;
		case "snowflake":
			return <Snowflake {...props} />;
		case "pass":
			return <Pass {...props} />;
		default:
			return null;
	}
};

export default Icon;
