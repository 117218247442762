import "./_page-productdetails.scss";

import * as React from "react";
import { connect } from "react-redux";
import { getSingleAccessProduct } from "../../../store/selectors/user";

import { Page, Container, ScrollView, ContainerHeader, Button, Currency } from "../../../components";
import { Utils } from "@sno_oslo/browser-utils";
import { format } from "date-fns";
// tslint:disable-next-line: no-submodule-imports
import * as nb from "date-fns/locale/nb";
import { Fetch } from "../../../store/Fetch";
import { Icon } from "../../../components/icon";

import { Link } from "react-router-dom";

const text: any = {
	productType: {
		daypass: "Dagspass",
		subscription: "Medlemskap",
	},
	addonType: {
		shower: "Garderobe med dusj",
	},
};

const formatTime = (time: Date) => {
	return format(time, "D. MMMM YYYY kl. HH:mm", { locale: nb as any });
};

const formatUnix = (time: number) => {
	const date = new Date(time * 1000);
	return format(date, "D. MMMM YYYY kl. HH:mm", { locale: nb as any });
};

interface IPassDetails {
	productName: string;
	type: string;
	userNameForPass: string;
	lockInEndDate: Date;
	expireTime: number;
	firstVisit: Date;
	purchasedAt: Date;
	price: number;
	isTransferable: boolean;
	nextPayment: any;
	paymentMethod: any;
	qrCode: string;
	stripePaymentMethodId: string;
}

const Product = (props: any) => {
	const product = props.data;
	const [passDetails, setPassDetails] = React.useState<IPassDetails | null>(null);
	const [loading, setLoading] = React.useState(false);

	React.useEffect(() => {
		setLoading(true);
		setTimeout(() => {
			Fetch.get("/user/{{env}}/access-products/" + product.qrCode).then((res) => {
				setPassDetails(res);
				setLoading(false);
			});
		}, 500);
	}, [product.qrCode]);

	async function generateRedeemCode() {
		await Fetch.get("/user/{{env}}/access-products/" + product.qrCode + "/redeem-code").then((resp) => {
			props.history.push({
				pathname: `/user/my-products/transfer/${product.id}/${resp}`,
				state: {
					direction: "bottom",
					passType: passDetails.type,
					userNameForPass: passDetails.userNameForPass,
				},
			});
		});
	}

	return (
		<>
			{loading ? (
				<div className="animate">
					<object
						aria-label="Loading animation"
						data="https://images.ctfassets.net/4gvqn75my5tn/5Tckpp33mkRhJq5zLFlcuR/9a97d853d9460c039dbf97c50dc8a6cb/image__4_.svg"
					></object>
				</div>
			) : (
				<div className="c-productdetails">
					{passDetails !== null ? (
						<>
							<Container>
								<ContainerHeader>
									{passDetails.type ? <h2>{text.productType[passDetails.type]}</h2> : ""}
									<p>
										{passDetails.type === "subscription"
											? passDetails.userNameForPass
											: passDetails.productName.replace(text.productType[passDetails.type], "")}
									</p>
								</ContainerHeader>
								<QRCode qr={product.qrCode} />
								<div className="c-productdetails--status" style={{ display: "none" }}>
									<p>Scan koden for å aktivere</p>
								</div>

								{passDetails.type === "subscription" ? (
									<div className="page-productdetails--validTo">
										<p>Gyldig til {formatTime(passDetails.lockInEndDate)}</p>
									</div>
								) : (
									<div className="page-productdetails--validTo">
										<p>Gyldig til {formatUnix(passDetails.expireTime)}</p>
									</div>
								)}
							</Container>

							<Container gray>
								{passDetails.nextPayment ? (
									<div className="container--gray--next-payment">
										<h3>Betaling</h3>
										<h5>Neste betaling</h5>
										<p>
											<Currency value={passDetails.nextPayment.amountDue}></Currency> trekkes den{" "}
											{formatTime(passDetails.nextPayment.timeStamp)}
										</p>
										<h5>Betalingskort</h5>
										<p>Medlemskapet betales med dette kortet</p>
										<div className="c-card">
											<div>
												<Icon
													type={
														passDetails.paymentMethod.brand === "visa"
															? "visa"
															: passDetails.paymentMethod.brand === "mastercard"
															? "mastercard"
															: "credit-card"
													}
												></Icon>
												<p>**** **** **** **** {passDetails.paymentMethod.last4}</p>
											</div>
											<p>
												Gyldig til {passDetails.paymentMethod.expMonth}/
												{passDetails.paymentMethod.expYear}
											</p>
										</div>
										<Link
											to={{
												pathname: "/user/my-products/editcard",
												state: {
													qrCode: passDetails.qrCode,
													stripePaymentMethodId: passDetails.stripePaymentMethodId,
													direction: "bottom",
												},
											}}
										>
											<Button grey thin>
												Endre betalingskort
											</Button>
										</Link>
									</div>
								) : null}

								<h3>Detaljer</h3>
								<ul>
									{passDetails.userNameForPass ? (
										<li className="c-productdetails--userName">
											Medlemskapet tilhører:
											<p>{passDetails.userNameForPass}</p>
										</li>
									) : null}
									{passDetails.productName ? <li>{passDetails.productName}</li> : null}
									{passDetails.firstVisit ? (
										<li>Aktivert {formatTime(passDetails.firstVisit)}</li>
									) : null}
									{passDetails.expireTime && !passDetails.firstVisit ? (
										<li>Utløper {formatUnix(passDetails.expireTime)}</li>
									) : null}
									{passDetails.purchasedAt ? (
										<li>Kjøpt {formatTime(passDetails.purchasedAt)}</li>
									) : null}
									{passDetails.type === "daypass" ? (
										passDetails.price ? (
											<li>
												Pris: <Currency value={passDetails.price} />{" "}
											</li>
										) : null
									) : null}
								</ul>
								{passDetails.isTransferable ? (
									<div className="c-productdetails--transfer-button">
										<Button grey thin onClick={generateRedeemCode}>
											Gi bort passet
										</Button>
									</div>
								) : null}
							</Container>
						</>
					) : null}
				</div>
			)}
		</>
	);
};

const QRCode = (props: any) => {
	const [qrCode, setQrCode] = React.useState(null);
	React.useEffect(() => {
		Utils.QRCode.getQrCode(props.qr, "svg", "#ffffffff", "#000000", 4, "H").then((res) => setQrCode(res));
	}, [props.qr]);
	return <div className="qrcode" dangerouslySetInnerHTML={{ __html: qrCode }} />;
};

export function ProductDetails(props: any) {
	const { product } = props;
	return (
		<Page
			className="page-productdetails"
			popup
			closeHandler={() => {
				props.history.push({ pathname: "/user/my-products", state: product.id });
			}}
		>
			<ScrollView>
				{product ? <Product history={props.history} data={product} /> : <p>Fant ikke produktet</p>}
			</ScrollView>
		</Page>
	);
}

export default connect((state, props: any) => {
	return {
		product: getSingleAccessProduct(state, props.match.params.productid),
	};
})(ProductDetails);
