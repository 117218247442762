import * as React from "react";
import "./_input.scss";

export interface IInputProps {
	label?: string;
	placeholder?: any;
}

const Input: React.FunctionComponent<IInputProps> = (props) => {
	return (
		<>
			<label className="c-label">{props.label}</label>
			<input
				type="text"
				name={props.label}
				className="c-input"
				placeholder={props.placeholder}
				onFocus={(e) => (e.target.placeholder = "")}
				onBlur={(e) => (e.target.placeholder = props.placeholder)}
			/>
		</>
	);
};

export default Input;
