import "./navigation.scss";

import * as React from "react";
import { NavLink } from "react-router-dom";
import { Icon, IconType } from "../icon";
import Only from "../only";
import Config from "../../store/Config";
import Touchable from "../touchable";

const Navigation = (props: any) => {
	const [count, setCount] = React.useState(0);
	const [showDebug, setShowDebug] = React.useState(false);

	React.useEffect(() => {
		if (count > 4) {
			setShowDebug(true);
		}
	}, [count]);
	return (
		<nav className="c-navigation">
			<ul className="c-navigation--list">
				<li className="c-navigation--list-element">
					<Touchable>
						<NavLink to={{ pathname: "/user/my-products", state: { direction: null } }}>
							<Icon type={IconType.LOGO} block />
							<span>Mine pass</span>
						</NavLink>
					</Touchable>
				</li>
				<li className="c-navigation--list-element">
					<Touchable>
						<NavLink to={{ pathname: "/products/buy", state: { direction: null } }}>
							<Icon type={IconType.PASS} block />
							<span>Kjøp pass</span>
						</NavLink>
					</Touchable>
				</li>
				<li
					className="c-navigation--list-element"
					onClick={() => {
						setCount(count + 1);
					}}
				>
					<Touchable>
						<NavLink to={{ pathname: "/user/profile", state: { direction: null } }}>
							<Icon type={IconType.PROFILE} block />
							<span>Profil</span>
						</NavLink>
					</Touchable>
				</li>
				<Only if={Config.ENV !== "production" && showDebug}>
					<li
						className="c-navigation--list-element"
						onClick={() => {
							setCount(count - 1);
						}}
					>
						<Touchable>
							<NavLink to={{ pathname: "/debug", state: { direction: null } }}>
								<Icon type={IconType.SNOWFLAKE} block />
								<span>Debug</span>
							</NavLink>
						</Touchable>
					</li>
				</Only>
			</ul>
		</nav>
	);
};

export default Navigation;
