import "../page/page.scss";
import * as React from "react";
import NativeBridge from "../../store/bridge/NativeBridge";
import NativeEvent from "../../store/bridge/NativeEvent";

export default class ScrollView extends React.Component {
	ref: any;

	constructor(props: any) {
		super(props);

		this.onStatusbarTapped = this.onStatusbarTapped.bind(this);
	}

	componentDidMount() {
		NativeBridge.listen(NativeEvent.STATUSBAR_TAPPED, this.onStatusbarTapped);
	}

	componentWillUnmount() {
		NativeBridge.unlisten(NativeEvent.STATUSBAR_TAPPED, this.onStatusbarTapped);
	}

	onStatusbarTapped() {
		// this might need more stuff with timeouts and stuff,
		// also, no animation for safari for now, replace with js animation
		this.ref.style.overflow = "hidden";
		this.ref.scrollTo(0, 0);
		this.ref.style.overflow = null;
	}

	render() {
		const { children } = this.props;

		return (
			<div className="page__scroll-view" ref={(ref) => (this.ref = ref)}>
				{children}
			</div>
		);
	}
}
