import * as React from "react";
import "./_buttongroup.scss";
import css from "classnames";

interface IButtonGroupProps {
	align?: string;
	className?: any;
	grey?: boolean;
}

const ButtonGroup: React.FunctionComponent<IButtonGroupProps> = (props) => {
	return (
		<div
			className={css("c-buttongroup", props.className, {
				"c-buttongroup--align-top": props.align === "top",
				"c-buttongroup--align-bottom": props.align === "bottom",
				"c-buttongroup--bg-grey": props.grey,
			})}
		>
			{props.children}
		</div>
	);
};

export default ButtonGroup;
